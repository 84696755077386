import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import store from 'store/dist/store.modern';
import Button from '@bfly/ui/Button';
import Modal from '@bfly/ui/Modal';

import { useShortcut } from './KeyboardShortcutManager';

const KEY = '@bfly-label-collector/instructions:';

function InstructionsModal({ username, task, readOnly, ...props }) {
  const seenKey = `${KEY} ${username},${task.name}`;
  const defaultShow = !store.get(seenKey, readOnly);
  const [show, setShow] = useState(defaultShow);

  const onHide = () => {
    setShow(false);
    store.set(seenKey, true);
  };

  useShortcut({
    '-': () => {
      if (show) onHide();
      else setShow(true);
    },
  });

  return (
    <Modal {...props} onHide={onHide} show={show}>
      <Modal.Header>
        <Modal.Title>Task Instructions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactMarkdown>{task.definition.instructions}</ReactMarkdown>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InstructionsModal;
