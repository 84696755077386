import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';

import AppPage from 'src/components/AppPage';
import Page from 'src/components/Page';
import TaskDefinitionForm from 'src/components/TaskDefinitionForm';

const propTypes = {
  data: PropTypes.object.isRequired,

  // The routing mechanism used for links.
  router: PropTypes.object.isRequired,
};

function NewTaskPage({ data, router }) {
  const { viewer } = data;

  const handleSubmit = (task) => {
    router.push(`/-/admin/tasks/${task.name}/reference-images`);
  };

  useEffect(() => {
    document.title = `New Task - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Page.Header backTo="/-/admin/tasks">
        <Header.Title>New Task Definition</Header.Title>
      </Page.Header>
      <MainContent size="medium">
        <TaskDefinitionForm onSave={handleSubmit} task={null} />
      </MainContent>
    </AppPage>
  );
}

NewTaskPage.propTypes = propTypes;
NewTaskPage.getData = async (args) => ({
  viewer: await AppPage.getData(args),
});

export default NewTaskPage;
