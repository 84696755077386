import { stylesheet } from 'astroturf';
import Link from 'found/Link';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import CheckIco from '@bfly/icons/CheckMarkSmall';
import Button from '@bfly/ui/Button';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';

import AppPage from 'src/components/AppPage';
import Table from 'src/components/Table';

const styles = stylesheet`
  .home {
    text-align: center;
  }

  .title {
    color: white;
  }

  .subtitle {
    margin-top: 0.2rem;
    white-space: nowrap;
  }
`;

const propTypes = {
  data: PropTypes.object.isRequired,
};

async function getData({ context }) {
  const { api } = context;

  const [viewer, users] = await Promise.all([
    AppPage.getData({ context }),
    api.getUsers(),
  ]);

  return { viewer, users };
}

function UserListPage({ data }) {
  const { viewer, users } = data;

  useEffect(() => {
    document.title = `Users - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Header>
        <Header.Title>Users</Header.Title>
        <Header.Actions align="center">
          <Link as={Button} to="/-/admin/users/-/new">
            + Add User
          </Link>
        </Header.Actions>
      </Header>
      <MainContent size="medium">
        <Table>
          <thead>
            <tr>
              <th className={styles.home}>User</th>
              <th>Contact Info</th>
              <th className={styles.home}>Enabled</th>
              <th className={styles.home}>Joined On</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.username}>
                <td>
                  <Link to={`/-/admin/users/${user.username}`}>
                    {user.username}
                  </Link>
                  <div className={styles.subtitle}>{user.name}</div>
                </td>
                <td>
                  <div className={styles.title}>{user.email}</div>
                  <div className={styles.subtitle}>{user.phone}</div>
                </td>

                <td className={styles.home}>{user.enabled && <CheckIco />}</td>
                <td className={styles.home}>
                  {new Date(user.created_at).toLocaleDateString({
                    date: 'medium',
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </MainContent>
    </AppPage>
  );
}

UserListPage.propTypes = propTypes;
UserListPage.getData = getData;

export default UserListPage;
