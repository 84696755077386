import React, { useContext } from 'react';
import Modal from '@bfly/ui/Modal';
import Button from '@bfly/ui/Button';
import Form from '@bfly/ui/Form';
import DropdownList from '@bfly/ui/DropdownList';
import { FormattedMessage, defineMessages } from 'react-intl';
import ToastContext, { ToastManager } from '@bfly/ui/ToastContext';

import schema from 'src/schema/Users';
import { useApi } from './AuthProvider';
import { Worklist } from 'src/schema/Worklist';
import executeWithErrorToast from 'src/utils/executeWithErrorToast';

const messages = defineMessages({
  placeholder: {
    id: 'duplicateWorklist.userList.placeholder',
    defaultMessage: 'Choose the user...',
  },
});

interface Props {
  worklist: Worklist;
  hideModal: boolean;
  setHideModal: (boolean) => void;
}

function DuplicateWorklistModal({ worklist, hideModal, setHideModal }: Props) {
  const toast = useContext<ToastManager | null>(ToastContext);
  const { id: worklistId, taskName } = worklist;

  const api = useApi();

  const [usersList, setUsersList] = React.useState();
  const [selectedUser, setSelectedUser] = React.useState();

  React.useEffect(() => {
    (async () => {
      const fetchedUsers = await api.getUsersForWorklist({
        taskName,
        grade: 'capable,onboarding',
      });
      const formatedUsers = fetchedUsers.map((user) => ({
        name: user.user,
        username: user.username,
      }));
      setUsersList(formatedUsers);
    })();
  }, [api, taskName]);

  const handleChange = (userData) => {
    const { username } = userData;
    setSelectedUser(username);
  };

  const handleSubmit = async (): Promise<any> => {
    setHideModal(true);
    toast!.info('Duplicating worklist...');
    const result = await executeWithErrorToast(toast, () =>
      api.duplicateWorklist({ worklistId, username: selectedUser }),
    );

    toast!.success(`Worklist Duplicated to ${selectedUser}`);

    return result;
  };

  return (
    <Modal show={hideModal} scrollable={false} variant="dark">
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="duplicateModal.title"
            defaultMessage="Duplicate Worklist"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form schema={schema as any}>
          <Form.FieldGroup
            name="username"
            label="User"
            className="items-center"
            placeholder={messages.placeholder}
            as={DropdownList}
            data={usersList}
            dataKey="username"
            textField={(v) => `${v.username} - (${v.name})`}
            onChange={handleChange}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button onClick={handleSubmit} disabled={!selectedUser}>
            <FormattedMessage
              id="jsonModal.confirm"
              defaultMessage="Confirm"
            />
          </Button>
          <Button variant="danger" onClick={() => setHideModal(true)}>
            <FormattedMessage id="jsonModal.close" defaultMessage="Close" />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

export default DuplicateWorklistModal;
