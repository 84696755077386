import { stylesheet } from 'astroturf';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '@bfly/ui/Modal';

const marketingWebsiteUrl = 'https://www.butterflynetwork.com/';
const butterflyCloudVersion = `${process.env.VERSION} (${process.env.BUILD_DATE})`;

const styles = stylesheet`
  @import '~@bfly/ui/styles/theme';
  .link {
    color: theme-color('primary');
    &:hover,
    &:focus {
      color: darken(theme-color('primary'), 15%);
    }
  }
`;

function AboutModal(props) {
  return (
    <Modal {...props}>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="aboutModal.title"
            defaultMessage="About Butterfly Annotation Cloud"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <FormattedMessage
            id="aboutModal.versionParagraph"
            defaultMessage="Butterfly Annotation Cloud {butterflyCloudVersion}"
            values={{ butterflyCloudVersion }}
          />
        </p>
        <p>
          Butterfly Network, Inc., 530 Old Whitfield Street, Guilford, CT 06437
        </p>
        <p>
          <a
            href={marketingWebsiteUrl}
            className={styles.link}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            rel="noreferrer"
          >
            {marketingWebsiteUrl}
          </a>
        </p>
        <p>
          <a
            href="https://www.butterflynetwork.com/privacy-policy"
            className={styles.link}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage
              id="aboutModal.privacyPolicy"
              defaultMessage="Privacy Policy"
            />
          </a>
        </p>
        <p>
          <a
            href="https://www.butterflynetwork.com/terms-of-use"
            className={styles.link}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage
              id="aboutModal.termsOfUse"
              defaultMessage="Terms of Use"
            />
          </a>
        </p>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}

export default AboutModal;
