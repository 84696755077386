import { useRouter } from 'found';
import { useEffect } from 'react';
import store from 'store/dist/store.modern';

import { useAuth } from 'src/components/AuthProvider';

const propTypes = {};

function LoginPage() {
  const { router, match } = useRouter();
  const auth = useAuth();

  async function handleAuth() {
    if (auth.isAuthenticated()) {
      router.replace('/');
      return;
    }

    try {
      if (await auth.acceptToken()) {
        const nextLocation = store.get('@bfly-label-collector/next-location');
        store.remove('@bfly-label-collector/next-location');
        router.replace(nextLocation || '/');
        return;
      }
    } catch (err: any) {
      store.remove('@bfly-label-collector/next-location');
      auth.login(err);
      return;
    }

    if (match?.location?.state?.nextLocation)
      store.set(
        '@bfly-label-collector/next-location',
        match?.location.state.nextLocation,
      );

    auth.login();
  }

  useEffect(() => {
    handleAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

LoginPage.propTypes = propTypes;

export default LoginPage;
