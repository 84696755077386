/* Contains a form for specifying a question type.

The QuestionForm is used in both the TaskDefinitionPage, when a user is
defining which questions belong to which tasks, as well as the SkipForm.
*/

import { stylesheet } from 'astroturf';
import React from 'react';
import { string } from 'yup';
import Layout from '@4c/layout';
import DropdownList from '@bfly/ui/DropdownList';
import Form from '@bfly/ui/Form';

import { BaseQuestion, Question } from '../schema/AnnotationTask';
import AccordionFieldArray from './AccordionFieldArray';

const styles = stylesheet`
  @import '~@bfly/ui/styles/theme';

  .innerAccordion {
    padding: 2rem;
  }
`;

const defaultQuestion = BaseQuestion.shape({
  questionId: string().default('').required(),
}).default();

const CONFIG_TYPE_FIELDS = {
  bool: ({ hideTagStyle }) => (
    <>
      {!hideTagStyle && (
        <Form.FieldGroup
          name="style"
          label="Style"
          as={DropdownList}
          data={['checkbox', 'button']}
          horizontal
        />
      )}
      <Form.FieldGroup name="defaultValue" label="Default Value" horizontal />
    </>
  ),

  number: () => (
    <>
      <Form.FieldGroup name="isInt" label="Integer" horizontal />

      <Form.FieldGroup
        name="minimumValue"
        label="Minimum"
        type="number"
        horizontal
      />

      <Form.FieldGroup
        name="maximumValue"
        label="Maximum"
        type="number"
        horizontal
      />

      <Form.FieldGroup
        name="defaultValue"
        label="Default Value"
        type="number"
        horizontal
      />
    </>
  ),

  string: () => (
    <Form.FieldGroup name="defaultValue" label="Default Value" horizontal />
  ),

  tag: ({ hideTagStyle }) => (
    <>
      {!hideTagStyle && (
        <Form.FieldGroup
          name="style"
          label="Style"
          as={DropdownList}
          data={['button', 'checkbox', 'dropdown']}
          horizontal
        />
      )}

      <AccordionFieldArray<{ displayText: string }>
        name="tagOptions"
        label="Options"
        className={styles.innerAccordion}
      >
        {({ value, helpers }) =>
          value.map((item, idx) => {
            const name = `tagOptions[${idx}]`;

            return (
              <AccordionFieldArray.Item
                key={name}
                name={name}
                title={item.displayText || 'Option'}
                onRemove={helpers.remove(item)}
              >
                <Layout justify="space-between">
                  <Form.Field
                    name="displayText"
                    placeholder="Display Text"
                    className="flex-fill m-2"
                  />

                  <Form.Field
                    name="value"
                    placeholder="Value"
                    className="flex-fill m-2"
                  />
                </Layout>

                <Layout justify="space-between">
                  <Form.Field
                    name="color"
                    placeholder="Color (hex)"
                    className="flex-fill m-2"
                  />

                  <Form.Field
                    name="shortcut"
                    placeholder="Keyboard Shortcut"
                    className="flex-fill m-2"
                  />
                </Layout>
              </AccordionFieldArray.Item>
            );
          })
        }
      </AccordionFieldArray>
    </>
  ),
};

interface QuestionProps {
  defaultTypeValue?: string;
  defaultRequiredValue?: boolean | null;
  hideTagStyle?: boolean;
}

export function QuestionForm({
  defaultTypeValue,
  defaultRequiredValue,
  hideTagStyle,
}: QuestionProps) {
  return (
    <Form.Field name="">
      {({ value }) => {
        const type = defaultTypeValue || (value && value.type);

        return (
          <>
            {!defaultTypeValue && (
              <Form.FieldGroup
                name="type"
                label="Type"
                as={DropdownList}
                data={['bool', 'number', 'string', 'tag']}
                horizontal
              />
            )}

            <Form.FieldGroup
              name="displayText"
              label="Display Text"
              horizontal
            />

            <Form.FieldGroup
              name="questionId"
              label="Question ID"
              horizontal
            />

            {defaultRequiredValue == null && (
              <Form.FieldGroup name="required" label="Required" horizontal />
            )}

            {type &&
              CONFIG_TYPE_FIELDS[type] &&
              CONFIG_TYPE_FIELDS[type]({ hideTagStyle })}
          </>
        );
      }}
    </Form.Field>
  );
}

function QuestionsForm() {
  return (
    <Form.FieldSet legend="Questions">
      <AccordionFieldArray<Question>
        name="definition.questions"
        label="Questions"
        labelSrOnly
        defaultValue={defaultQuestion}
      >
        {({ value, helpers }) =>
          value.map((item, idx) => {
            const name = `definition.questions[${idx}]`;

            return (
              <AccordionFieldArray.Item
                key={name}
                name={name}
                onRemove={helpers.remove(item)}
                title={
                  item && item.displayText
                    ? `${idx + 1}. ${item.displayText}`
                    : 'New Question'
                }
              >
                <QuestionForm />
              </AccordionFieldArray.Item>
            );
          })
        }
      </AccordionFieldArray>
    </Form.FieldSet>
  );
}

export default QuestionsForm;
