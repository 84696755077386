import { stylesheet } from 'astroturf';
import Link from 'found/Link';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Button from '@bfly/ui/Button';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';

import AppPage from 'src/components/AppPage';
import Table from 'src/components/Table';

const styles = stylesheet`
  .home {
    text-align: center;
  }
`;

const propTypes = {
  data: PropTypes.object.isRequired,
};

async function getData({ context }) {
  const { api } = context;
  const [viewer, reviewTasks] = await Promise.all([
    AppPage.getData({ context }),
    api.getReviewTasks(),
  ]);
  return { viewer, reviewTasks };
}

function ReviewTaskListPage({ data }) {
  const { viewer, reviewTasks } = data;

  useEffect(() => {
    document.title = `Review Tasks - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Header>
        <Header.Title>Tasks</Header.Title>
        <Header.Actions align="center">
          <Link as={Button} to="/-/admin/review_tasks/-/new">
            New
          </Link>
        </Header.Actions>
      </Header>
      <MainContent size="medium">
        <Table>
          <thead>
            <tr>
              <th className={styles.home}>Review Task</th>
              <th className={styles.home}>Updated</th>
            </tr>
          </thead>
          <tbody>
            {reviewTasks.map(
              ({
                name,
                latest_version: { version, created_at: createdAt },
              }) => (
                <tr key={name}>
                  <td>
                    <Link to={`/-/admin/review_tasks/${name}`}>{name}</Link>
                    <div className="mt-2 text-monospace">v{version}</div>
                  </td>
                  <td className={styles.home}>
                    {new Date(createdAt).toLocaleString()}
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </Table>
      </MainContent>
    </AppPage>
  );
}

ReviewTaskListPage.propTypes = propTypes;
ReviewTaskListPage.getData = getData;

export default ReviewTaskListPage;
