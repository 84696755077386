import styled from 'astroturf/react';
import { stylesheet } from 'astroturf';
import React from 'react';
import {
  AmbientLight,
  ColladaModel,
  DirectionalLight,
  Light,
  Object3D,
  PerspectiveCamera,
  Quaternion,
  Scene,
  WebGLRenderer,
} from 'three';

import TraceableCine from './TraceableCine';

let cachedRenderedObjectName: string | null = null;
let cachedRenderedObject: Object3D | null = null;

const RENDER_OBJECT_MAP = {
  probeIq: {
    assetFilename: 'probe-iq.dae',
    objectName: 'Probe',
  },
  probeIqPlus: {
    assetFilename: 'probe-iq-plus.dae',
    objectName: 'Probe-Plus',
  },
};

const CINE_WIDTH = 800;
const CINE_HEIGHT = 600;

const styles = stylesheet`
  .container {
    position: relative;
  }
`;

// Opacity in CSS looks more 3D than in threejs, for some reason
// Make semitransparent so it's eaiser to line up with underlying image
const ThreeJsOverlay = styled('div')`
  position: absolute;
  opacity: 0.4;
  pointer-events: none;
  top: 0;
  width: ${CINE_WIDTH}px;
  height: ${CINE_HEIGHT}px;
  left: 50%;
  transform: translateX(-50%);
`;

async function loadProbeModel(renderObjectName: string): Promise<Object3D> {
  // Need to be careful here: We don't want to just cache the 3D model in case
  // a user transitions from task A (with model A) to task B (with model B).
  // Consequently, we'll only reload the model when the task changes.
  if (cachedRenderedObjectName === renderObjectName)
    return cachedRenderedObject;

  const { ColladaLoader } = await import(
    'three-full/sources/loaders/ColladaLoader'
  );

  const objectToRender = RENDER_OBJECT_MAP[renderObjectName];
  const importedModel = await import(
    `../assets/${objectToRender.assetFilename}`
  );

  return new Promise((resolve) => {
    new ColladaLoader().load(importedModel.default, (model: ColladaModel) => {
      const probeObject = model.scene.children.find(
        (c) => c.name === objectToRender.objectName,
      );
      cachedRenderedObject = probeObject!;
      cachedRenderedObjectName = renderObjectName;
      resolve(probeObject!);
    });
  });
}

function createLights(): Light[] {
  const ambientLight = new AmbientLight(0xffffff);
  ambientLight.position.x = 0;
  ambientLight.position.y = 100;
  ambientLight.position.z = -50;
  ambientLight.intensity = 0.8;

  // Add a bright light off to the corner and coming from the front, which
  // gives some 3-dimensionality. Then a weak light on the other side to fill
  // in the shadows.
  const spotLight = new DirectionalLight(0xd3d3d3);
  spotLight.position.x = 100;
  spotLight.position.y = 100;
  spotLight.position.z = 50;
  spotLight.intensity = 0.8;
  spotLight.castShadow = true;

  const spotLight2 = new DirectionalLight(0xd3d3d3);
  spotLight2.position.x = -100;
  spotLight2.position.y = -100;
  spotLight2.position.z = 50;
  spotLight2.intensity = 0.2;

  return [ambientLight, spotLight, spotLight2];
}

function createCamera(width: number, height: number, fieldOfView: number) {
  const camera = new PerspectiveCamera(
    fieldOfView,
    width / height, // or reverse
    0.1, // near
    150, // far cm
  );
  camera.name = 'Camera';
  camera.position.set(0, 0, 0);

  return camera;
}

class ColladaOverlayCine extends React.Component<any, any> {
  threeJsOverlayRef = React.createRef<HTMLDivElement>();

  scene: Scene | null = null;

  renderer: WebGLRenderer | null = null;

  camera: PerspectiveCamera | null = null;

  probe: Object3D | null = null;

  state = {};

  componentDidMount() {
    const renderer = new WebGLRenderer({ alpha: true });

    renderer.setClearColor(0xffffff, 0); // transparent

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(800, 600);

    const scene = new Scene();

    scene.add(...createLights());

    this.threeJsOverlayRef.current!.appendChild(renderer.domElement);

    loadProbeModel(this.props.renderObject).then((probe) => {
      this.renderer = renderer;
      this.scene = scene;
      this.probe = probe;

      scene.add(probe);
    });
  }

  async componentDidUpdate() {
    const pose = await this.fetchPose();
    this.updateScene(pose);
    this.updateScene(pose);
  }

  canSolvePnp() {
    const { results } = this.props;
    return (
      Object.values(results).filter(
        (element: any[] | null) => element && element.length,
      ).length >= 5
    );
  }

  fetchPose() {
    const { api, assignment, results } = this.props;
    if (!assignment || !results || !this.canSolvePnp()) {
      return null;
    }
    return api.getProbePose(assignment.assignment_id, results);
  }

  updateScene(pose) {
    if (this.renderer && this.scene && this.probe) {
      if (pose) {
        if (!this.camera) {
          const focalLength = pose.focal_length;
          const fieldOfView =
            (2 * Math.atan(480 / (2 * focalLength)) * 180.0) / Math.PI;
          this.camera = createCamera(800, 600, fieldOfView);
        }
        this.probe.visible = true;
        const quaternion = new Quaternion(
          pose.quaternions[1],
          pose.quaternions[2],
          pose.quaternions[3],
          pose.quaternions[0],
        ).normalize();
        // OpenCV has a coordinate system that is 180 degree around the x axis
        // from the one in three js.
        const toThreeCoordinate = new Quaternion(1.0, 0.0, 0.0, 0.0);

        const x = pose.translation[0];
        const y = pose.translation[1];
        const z = pose.translation[2];

        this.probe.rotation.setFromQuaternion(
          toThreeCoordinate.multiply(quaternion),
        );
        this.probe.position.set(x, -y, -z);
      } else {
        this.probe.visible = false;
      }
      if (this.camera) {
        this.renderer.render(this.scene, this.camera);
      }
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <TraceableCine {...this.props} />
        <ThreeJsOverlay ref={this.threeJsOverlayRef} />
      </div>
    );
  }
}

export default ColladaOverlayCine;
