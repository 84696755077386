import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';
import TabNav from '@bfly/ui/TabNav';

import AppPage from 'src/components/AppPage';
import Page from 'src/components/Page';

const propTypes = {
  data: PropTypes.object.isRequired,
};

async function getData({ params, context }) {
  const { username } = params;
  const { api } = context;
  const isCreating = !username;

  const [viewer, user] = await Promise.all([
    AppPage.getData({ context }),
    isCreating ? null : api.getUser(username),
  ]);

  return { viewer, user };
}

function UserPage({ data, children }) {
  const { viewer, user } = data;

  useEffect(() => {
    document.title = `User Details - ${user.username} - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Page.Header backTo="/-/admin/users">
        <Header.Title>{user.name}</Header.Title>
      </Page.Header>

      <MainContent>
        <TabNav>
          <TabNav.LinkItem to={`/-/admin/users/${user.username}`} exact>
            Profile
          </TabNav.LinkItem>
          <TabNav.LinkItem
            to={`/-/admin/users/${user.username}/contracts`}
            exact
          >
            Contracts
          </TabNav.LinkItem>
          <TabNav.LinkItem to={`/-/admin/users/${user.username}/worklists`}>
            Worklists
          </TabNav.LinkItem>
        </TabNav>
        {children}
      </MainContent>
    </AppPage>
  );
}

UserPage.propTypes = propTypes;
UserPage.getData = getData;

export default UserPage;
