import { stylesheet } from 'astroturf';
import cx from 'classnames';
import React, { useMemo } from 'react';
import DropdownList from '@bfly/ui/DropdownList';
import Form from '@bfly/ui/Form';

import { Contract, Task } from '../models';
import schema from '../schema/Contract';

const styles = stylesheet`
  @import '~@bfly/ui/styles/theme';

  .muted {
    &,
    & input {
      color: theme-value(dark, 'text-color');
    }
  }

  .title {
    color: white;
    width: 100%;
  }
  .twoColumns {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .rate {
    min-width: 12rem;
  }
  .grade {
    min-width: 18rem;
    text-align: left !important;
  }

  .enabled {
    padding-left: 1.8rem;
    margin-bottom: 0;
    display: inline-block;
  }

  .review-task-row .title {
    padding-left: 2rem !important;
  }
`;

const grades = [
  { value: 'incapable', text: 'Incapable' },
  { value: 'onboarding', text: 'Onboarding' },
  { value: 'capable', text: 'Capable' },
];

interface Props {
  contract: Contract & { user?: string };
  username?: string;
  task: Task | string;
  onChange: (contract: Contract) => void;
  isReviewTask?: boolean;
  showUserInfo?: boolean;
}

export default function ContractRow({
  contract,
  username,
  task,
  onChange,
  isReviewTask,
  showUserInfo = false,
}: Props) {
  const taskName = typeof task === 'string' ? task : task.name;

  const defaultValue = useMemo(
    () => ({ ...schema.default(), task: taskName, username }),
    [username, taskName],
  );

  const handleChange = (value) => {
    // eslint-disable-next-line no-param-reassign
    if (value.grade === 'incapable') value.assignmentEnabled = false;

    onChange(value);
  };

  return (
    <Form
      as="tr"
      noValidate
      onChange={handleChange}
      schema={schema as any}
      defaultValue={defaultValue}
      value={contract}
      className={isReviewTask ? styles.reviewTaskRow : ''}
    >
      {showUserInfo ? (
        <td style={{ width: '100%' }}>
          <div className={styles.twoColumns}>
            <span className={styles.title}>{contract.username}</span>
            <span className="text-monospace">{contract.user}</span>
          </div>
        </td>
      ) : (
        <td className={styles.title}>{taskName}</td>
      )}

      <td className={styles.rate}>
        <Form.Field
          name="rate"
          type="number"
          className={cx((!contract || !contract.rate) && styles.muted)}
        />
      </td>
      <td className={styles.grade}>
        <Form.Field
          name="grade"
          as={DropdownList}
          data={grades}
          mapFromValue={(v: any) => v && v.value}
          dataKey="value"
          textField="text"
          renderValue={({ item }) => (
            <span className={cx(item.value === 'incapable' && styles.muted)}>
              {item.text}
            </span>
          )}
        />
      </td>
      <td className="text-right px-0">
        <Form.Field
          name="assignmentEnabled"
          disabled={!contract || contract.grade === 'incapable'}
          className={styles.enabled}
        />
      </td>
    </Form>
  );
}
