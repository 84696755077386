import styled from 'astroturf/react';
import React, { useState } from 'react';
import MainContent from '@bfly/ui/MainContent';

import { useTriggerShortcut } from 'src/components/KeyboardShortcutManager';
import Shortcut from 'src/components/Shortcut';
import TagButton from 'src/components/TagButton';
import TracesFieldset from 'src/components/TracesFieldset';
import TracesReviewCine from './TracesReviewCine';

const Sidebar = styled('div')`
  @import '~@bfly/ui/styles/theme';

  position: sticky;
  top: 60px;
  bottom: 0;
  padding: 1.6rem;
  width: 30rem;
`;

function TracesReview({
  task,
  labels,
  images,
  file,
  onChangeDisabled,
  disabled,
}) {
  const [activeTrace, setActiveTrace] = useState(
    task.definition.traces[0].traceId,
  );

  const prev = useTriggerShortcut('ArrowUp');
  const next = useTriggerShortcut('ArrowDown');

  return (
    <>
      <MainContent size="large">
        <TracesReviewCine
          task={task}
          labels={labels}
          file={file}
          images={images}
          activeTraceId={activeTrace}
          onDisable={onChangeDisabled}
          disabledTraces={disabled}
        />
      </MainContent>
      <Sidebar>
        <TracesFieldset
          traces={task.definition.traces}
          onSelectTrace={setActiveTrace}
          activeTraceId={activeTrace}
        />

        <TagButton onClick={prev}>
          <Shortcut color="#000" title="arrow down">
            <i className="fas fa-arrow-down" style={{ fontSize: '85%' }} />
          </Shortcut>
          Previous Trace
        </TagButton>
        <TagButton onClick={next}>
          <Shortcut color="#000" title="arrow up">
            <i className="fas fa-arrow-up" style={{ fontSize: '85%' }} />
          </Shortcut>
          Next Trace
        </TagButton>
      </Sidebar>
    </>
  );
}

export default TracesReview;
