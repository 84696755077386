import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@bfly/ui/Button';
import Form from '@bfly/ui/Form';
import Modal from '@bfly/ui/Modal';

import TaskDefinitionControl from './TaskDefinitionControl';

function JsonTaskDefinitionModal({ onHide, ...modalProps }) {
  return (
    <Modal {...modalProps} backdrop>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="jsonModal.title"
            defaultMessage="Task Definition JSON"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>
          Task Definition
          <Form.Field name="definition" validateOn="change">
            {({ value, onChange, ...props }) => (
              <TaskDefinitionControl
                {...props}
                value={JSON.stringify(value, null, 2)}
                onChange={(val) => onChange(JSON.parse(val))}
                rows="20"
              />
            )}
          </Form.Field>
        </label>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button onClick={onHide}>
            <FormattedMessage id="jsonModal.close" defaultMessage="Close" />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

export default JsonTaskDefinitionModal;
