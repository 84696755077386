import { stylesheet } from 'astroturf';
import Link from 'found/Link';
import React, { useContext, useEffect, useState } from 'react';
import Button from '@bfly/ui/Button';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';
import { useApi } from 'src/components/AuthProvider';
import executeWithErrorToast from '../utils/executeWithErrorToast';
import ToastContext from '@bfly/ui/ToastContext';
import AppPage from 'src/components/AppPage';
import Table from 'src/components/Table';

const styles = stylesheet`
  .title {
    color: white;
  }
`;

async function getData({ context }) {
  const { api } = context;

  const [viewer, customEarnings] = await Promise.all([
    AppPage.getData({ context }),
    api.getCustomEarnings(),
  ]);

  return { viewer, customEarnings };
}

function CustomEarningsListPage({ data }) {
  const { viewer } = data;

  const api = useApi();
  const toast = useContext(ToastContext);

  const [customEarnings, setCustomEarnings] = useState(data.customEarnings);

  const deleteCustomPayment = async (id: string) => {
    await executeWithErrorToast(toast, () => api.deleteCustomEarnings(id));
    setCustomEarnings((prev) => prev.filter((earning) => earning.id !== id));
  };

  useEffect(() => {
    document.title = `Custom Earnings - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Header>
        <Header.Title>Custom Earnings</Header.Title>
        <Header.Actions align="center">
          <Link as={Button} to="/-/admin/custom_earnings/-/new">
            New
          </Link>
        </Header.Actions>
      </Header>
      <MainContent size="medium">
        <Table>
          <thead>
            <tr>
              <th>User</th>
              <th>Title</th>
              <th>Amount</th>
              <th>Created On</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {customEarnings.map((earnings) => (
              <tr key={earnings.username}>
                <td>
                  <div className={styles.title}>{earnings.username}</div>
                </td>
                <td>
                  <div className={styles.title}>{earnings.title}</div>
                </td>

                <td>${earnings.amount}</td>
                <td>
                  {new Date(earnings.created_at).toLocaleDateString('en-US')}
                </td>
                <td>
                  <Button onClick={() => deleteCustomPayment(earnings.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </MainContent>
    </AppPage>
  );
}

CustomEarningsListPage.getData = getData;
export default CustomEarningsListPage;
