import styled from 'astroturf/react';
import PropTypes from 'prop-types';
import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Header from '@bfly/ui/Header';

import AssignmentForm from 'src/components/AssignmentForm';
import Page from 'src/components/Page';

const WorklistProgress = styled(ProgressBar)`
  height: 0.4rem;
  border-radius: 0;
`;

const propTypes = {
  data: PropTypes.object.isRequired,

  match: PropTypes.object.isRequired,

  // The routing mechanism used for links.
  router: PropTypes.object.isRequired,
};

function WorklistAssignmentPage(props) {
  const {
    data,
    router,
    match: { params, location },
  } = props;

  const { state: { lastAssignment } = {} } = location;
  const { worklist, label, reviewTask, assignment, task } = data;

  const handlePrevious = () => {
    const base = `${params.username}/worklists/${params.worklistId}`;
    router.replace(`/${base}/${lastAssignment}/edit`);
  };

  const handleSubmit = () => {
    router.replace({
      pathname: `/${params.username}/worklists/${params.worklistId}`,
      state: { lastAssignment: assignment.assignment_id },
    });
  };

  const readOnly = !!label || !!reviewTask;

  return (
    <AssignmentForm
      {...props}
      onSubmit={handleSubmit}
      readOnly={readOnly}
      header={
        <>
          <WorklistProgress now={worklist.completedRatio} />
          <Page.Header backTo={`/${params.username}`}>
            <Header.Title>
              {worklist.name}: {task.name}
            </Header.Title>

            <Header.Actions align="center" pad>
              {!readOnly && !!lastAssignment && (
                <Header.Action onClick={handlePrevious}>
                  Edit Last Task
                </Header.Action>
              )}
              <AssignmentForm.Submit
                data={data}
                readOnly={readOnly}
                onSubmit={handleSubmit}
              />
            </Header.Actions>
          </Page.Header>
        </>
      }
    />
  );
}

WorklistAssignmentPage.propTypes = propTypes;
WorklistAssignmentPage.getData = AssignmentForm.getData;

export default WorklistAssignmentPage;
