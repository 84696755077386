import curry from 'lodash/curry';
import { useCallback, useMemo } from 'react';
import { ObjectSchema, mixed } from 'yup';

import { useApi } from 'src/components/AuthProvider';
import { Project } from '../models';
import useFetchHandler, { FetchResult } from './useFetchHandler';

interface ArrayLike<T> {
  [key: number]: T;
}

/**
 * Convert an analytics data array-like object to an array.
 * These objects look like {0: A, 1: B, 2: C} but does not have a length.
 * @param d
 */
export function fromObjectArray<T>(d: ArrayLike<T>) {
  const { length } = Object.keys(d);
  return Array.from<T>({ ...d, length });
}

export const analyticsArrayType = mixed()
  .default([])
  .transform(fromObjectArray);

// ==============================================================

type ProjectType = 'presets' | 'tasks';

/**
 * Creates a filter based upon a project and project type.
 * @param project - the selected project
 * @param type - the type of project presets or tasks
 * @param items - the data item that you want to be filtered
 */
export const useProjectFilterFactory = (
  project: Project | undefined,
  type: ProjectType,
  items: string[],
) =>
  useCallback(
    (data: any) => {
      if (!project) return data;
      if (type === 'tasks') {
        return data.filter((_, i) =>
          project[type].find((name) => name === items[i]),
        );
      }
      return data.filter((_, i) =>
        project[type].find((preset) => preset.name === items[i]),
      );
    },
    [project, items, type],
  );

/**
 * Override FetchResult's `data` to be non-nullable
 */
interface AnalyticsDataResult<T> extends FetchResult<T> {
  data: T;
}

/**
 * Creates a fetch handler for an analytics name and returns deserialized data.
 * @param analyticsName - the analytics name that you want (eg. "organization/velocity")
 * @param schema - the yup schema to deserialize the analytics data
 */
export function useAnalyticsData<T>(
  analyticsName: string,
  schema: ObjectSchema<any>,
): AnalyticsDataResult<T> {
  const api = useApi();
  const defaultValue = useMemo(() => schema.default(), [schema]);

  const thenable = () =>
    api.getAnalytics(analyticsName).then((result) => schema.cast(result));

  const result = useFetchHandler<T>(thenable, [analyticsName]);
  return { ...result, data: result.data || defaultValue };
}

/**
 * Construct the task analytics name
 * @param task - the selected task object
 * @param analyticsName - the analytics type
 */
export const getTaskAnalyticsName = curry(
  (task, analyticsName) => `task/${task.name}/${analyticsName}`,
);

const WEEKS_IN_YEAR = 52;
export const WEEKS_AGO_YEAR_LIST = Array.from(
  { length: WEEKS_IN_YEAR },
  (_, i) => i,
);
export const WEEKS_AGO_AXIS_LABELS = [
  'Last Week',
  '1 Week Ago',
  ...WEEKS_AGO_YEAR_LIST.slice(2).map((i) => `${i} Weeks Ago`),
];
