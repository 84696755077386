import { BaseTrace } from '../types.d';

export type Range = [number, number];

export type Annotation = {
  val: number;
  date: Date;
  traceId: string;
};

export function rangeDiff(
  range: Range,
  amount: number,
  invert = false,
): number {
  const [start, end] = range;
  return Math.abs(end - start) * amount * (invert ? -1 : 1);
}

export function zoom(
  range: Range,
  direction: 'in' | 'out',
  zoomAmount = 0.1,
): Range {
  const [start, end] = range;

  const diff = rangeDiff(range, zoomAmount, direction === 'in');
  return [start - diff, end + diff];
}

export function step(
  range: Range,
  direction: 'right' | 'left',
  stepAmount: number,
): Range {
  const [start, end] = range;

  const diff = rangeDiff(range, stepAmount, direction === 'left');
  return [start + diff, end + diff];
}

export function toShape(trace: BaseTrace, xValue: number) {
  return {
    type: 'line',
    xref: 'x',
    yref: 'paper',
    x0: xValue,
    y0: 0,
    x1: xValue,
    y1: 1,
    opacity: 1,
    line: {
      width: 3,
      color: trace.color,
      dash: 'none',
    },
  };
}

export function findNearest(
  rangeCenter,
  minRange,
  annotations,
): Annotation | null {
  let nearest = null as Annotation | null;

  Object.keys(annotations).forEach((key) => {
    annotations[key].forEach((annotation) => {
      if (
        !nearest ||
        rangeDiff([nearest.val, rangeCenter], 1.0) >
          rangeDiff([annotation.val, rangeCenter], 1.0)
      ) {
        nearest = { ...annotation, traceId: key };
      }
    });
  });

  return nearest && rangeDiff([nearest!.val, rangeCenter], 1.0) >= minRange
    ? null
    : nearest;
}
