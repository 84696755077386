import React from 'react';
import FormControl from '@bfly/ui/FormControl';

const propTypes = {};

function NumberInput({ value, onChange }) {
  return (
    <FormControl
      variant="secondary"
      type="number"
      value={value}
      onChange={onChange}
    />
  );
}

NumberInput.propTypes = propTypes;

export default NumberInput;
