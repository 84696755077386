import { stylesheet } from 'astroturf';
import classNames from 'classnames';
import React from 'react';
import FormControlErrorWrapper from '@bfly/ui/FormControlErrorWrapper';

import formStyles from '@bfly/ui/FormControls.module.scss';

const styles = stylesheet`
  @import '~@bfly/ui//styles/theme';

  .control {
    composes: form-control from '~@bfly/ui/FormControls.module.scss';

    height: auto;
    width: 100%;
  }
`;

function Textarea({
  meta,
  invalid,
  theme = 'dark',
  inputRef,
  value,
  ...props
}) {
  return (
    <FormControlErrorWrapper
      invalid={invalid == null && meta ? meta.invalid : invalid}
    >
      <textarea
        {...props}
        ref={inputRef}
        value={value == null ? '' : value}
        className={classNames(styles.control, formStyles[theme])}
      />
    </FormControlErrorWrapper>
  );
}

export default Textarea;
