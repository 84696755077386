import React, { lazy, useEffect, useState } from 'react';
import Layout from '@4c/layout';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';

import AppPage from 'src/components/AppPage';
import ProjectFilterDropdown from 'src/components/ProjectFilterDropdown';
import { Project } from '../models/Project';

const FilePerMonthChart = lazy(
  () =>
    import(
      /* webpackChunkName: "analytic-charts" */
      '../components/FilePerMonthChart'
    ),
);

const SourcingVelocityChart = lazy(
  () =>
    import(
      /* webpackChunkName: "analytic-charts" */
      '../components/SourcingVelocityChart'
    ),
);

async function getData({ context }) {
  const { api } = context;

  const [viewer, projects] = await Promise.all([
    AppPage.getData({ context }),
    api.getProjects(),
  ]);

  return {
    viewer,
    projects,
  };
}

function OrganizationAnalyticsPage({ data }) {
  const { viewer, projects } = data;

  const [project, setProject] = useState<Project>();

  useEffect(() => {
    document.title = `Organization Analytics - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Header>
        <Header.Title>Organization Analytics</Header.Title>
      </Header>
      <MainContent size="large">
        <React.Suspense fallback="loading...">
          {projects.length > 0 && (
            <Layout justify="flex-start">
              <ProjectFilterDropdown
                projects={projects}
                value={project && project.name}
                onChange={(d) => setProject(d)}
              />
            </Layout>
          )}

          <FilePerMonthChart project={project} />
          <SourcingVelocityChart project={project} />
        </React.Suspense>
      </MainContent>
    </AppPage>
  );
}

OrganizationAnalyticsPage.propTypes = {};
OrganizationAnalyticsPage.getData = getData;

export default OrganizationAnalyticsPage;
