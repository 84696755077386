import { stylesheet } from 'astroturf';
import classNames from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import BackArrowLink from '@bfly/ui/BackArrowLink';
import Drawer from '@bfly/ui/Drawer';
import Header from '@bfly/ui/Header';
import ToggleButton from '@bfly/ui/ToggleButton';
import { LinkProps } from 'found';

const styles = stylesheet`
  @import '~@bfly/ui/styles/theme';

  $navbar-plus-app-banner-height: $navbar-height + $app-banner-height;

  .container {
    position: relative;
    z-index: 0;
    top: 0;
    bottom: 0;

    &.center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      min-height: 100vh;
    }

    [data-bni-navbar] ~ & {
      top: $navbar-height;

      &.center {
        min-height: calc(100vh - #{$navbar-height});
        padding-bottom: $navbar-height;
      }
    }

    & {
      top: $navbar-plus-app-banner-height;

      &.center {
        min-height: calc(100vh - #{$navbar-plus-app-banner-height});
        padding-bottom: $navbar-plus-app-banner-height;
      }
    }

    :global(.fill-viewport) & {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }

  .side-panel {
    composes: scrollable from '~@bfly/ui/styles/utils.module.scss';

    position: fixed;
    z-index: $zindex-side-panel;
    top: $navbar-height;
    bottom: 0;
    width: $side-panel-width-lg;
    padding-bottom: $page-bottom-padding;
    // Specify background to prevent scrolled content from appearing below.
    background: $bg-color;
  }

  .side-panel-drawer {
    composes: side-panel;

    box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.3);
  }

  .side-panel-static {
    composes: side-panel;

    border-right: 1px solid $frame-border-color;

    .container > & {
      top: $navbar-plus-app-banner-height;
    }
  }

  .main {
    padding-left: 0;

    .side-panel-static + & {
      padding-left: $side-panel-width-lg;

      :global(.fill-viewport) & {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: $side-panel-width-lg;
        padding-left: 0;
      }
    }
  }

  .sticky {
    position: sticky;
    top: $navbar-height;
    z-index: $zindex-fixed;

    .container & {
      top: $navbar-plus-app-banner-height;
    }
  }
`;

interface PageContextValue {
  showDrawer: boolean;
  hasDrawer: boolean;
  registerDrawer(hasDrawer: boolean): void;
  toggleDrawer(open?: boolean): void;
}

const PageContext = React.createContext<PageContextValue>(null as any);

export function useToggleDrawer() {
  return useContext(PageContext).toggleDrawer;
}

export interface PageProps {
  children?: React.ReactNode;
}

function Page({ children }: PageProps) {
  const [hasDrawer, registerDrawer] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const context = useMemo(
    () => ({
      hasDrawer,
      showDrawer,
      registerDrawer,
      toggleDrawer: (nextShow) => {
        setShowDrawer((prevShow) => (nextShow == null ? !prevShow : nextShow));
      },
    }),
    [hasDrawer, showDrawer],
  );
  return (
    <PageContext.Provider value={context}>{children}</PageContext.Provider>
  );
}

export interface PageContainerProps {
  center?: boolean;
  children: React.ReactNode;
  className?: string;
}

function PageContainer({
  center,
  className,
  children,
  ...props
}: PageContainerProps) {
  return (
    <div
      className={classNames(
        className,
        styles.container,
        center && styles.center,
      )}
      {...props}
    >
      {children}
    </div>
  );
}

interface PageSidePanelProps {
  className?: string;
  useDrawer: boolean;
  children?: React.ReactNode;
}

function PageSidePanel({ useDrawer, ...props }: PageSidePanelProps) {
  const { showDrawer, toggleDrawer, registerDrawer } = useContext(PageContext);

  const handleHide = () => toggleDrawer(false);

  useEffect(() => {
    if (!useDrawer) toggleDrawer(false);

    registerDrawer(useDrawer);
    return () => {
      registerDrawer(false);
    };
  }, [useDrawer, registerDrawer, toggleDrawer]);

  return useDrawer ? (
    <Drawer
      {...props}
      show={showDrawer}
      onHide={handleHide}
      className={classNames(props.className, styles.sidePanelDrawer)}
    />
  ) : (
    <div
      {...props}
      className={classNames(props.className, styles.sidePanelStatic)}
    />
  );
}

export interface PageMainProps {
  className?: string;
  children?: React.ReactNode | React.ReactElement[];
}

function PageMain({ className, ...props }: PageMainProps) {
  return <main {...props} className={classNames(className, styles.main)} />;
}

interface PageHeaderProps {
  backTo?: LinkProps['to'];
  children?: React.ReactNode;
  className?: string;
  sticky?: boolean;
}

function PageHeader({ backTo, sticky, children, className }: PageHeaderProps) {
  const { hasDrawer, toggleDrawer } = useContext(PageContext);

  let action;
  if (hasDrawer) action = <ToggleButton onClick={() => toggleDrawer()} />;
  else if (backTo) action = <BackArrowLink to={backTo} />;

  return (
    <Header className={classNames(className, sticky && styles.sticky)}>
      {action}
      {children}
    </Header>
  );
}

Page.Container = PageContainer;
Page.SidePanel = PageSidePanel;
Page.Main = PageMain;
Page.Header = PageHeader;

export default Page;
