import { stylesheet } from 'astroturf';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '@4c/layout';
import ActiveLink from '@bfly/ui/ActiveLink';
import DropdownIcon from '@bfly/icons/Dropdown';
import Dropdown from '@bfly/ui/Dropdown';
import Nav from '@bfly/ui/Nav';
import Navbar from '@bfly/ui/Navbar';

import AboutModal from './AboutModal';
import { useApi, useAuth } from './AuthProvider';
import Page from './Page';

interface PageContextValue {
  viewerIsAdmin: boolean;
}

export const PageContext = React.createContext<PageContextValue>(null as any);

const styles = stylesheet`
  .button {
    display: flex;
    align-items: center;
  }

  .avatar {
    margin-right: 10px;
  }

  .arrow {
    margin-left: 1rem;
  }
`;

interface Props {
  sidePanel?: boolean;
  useDrawer?: boolean;
  viewer?: { username: string } | null;
  header?: React.ReactNode;
  children?: React.ReactNode;
}

function AppPage({
  sidePanel = true,
  useDrawer = false,
  viewer,
  header,
  children,
}: Props) {
  const [show, setShow] = useState(false);
  const onHide = () => setShow(false);

  const auth = useAuth();
  const api = useApi();

  const pageContext = useMemo(
    () => ({
      viewerIsAdmin: api.viewerIsAdmin,
    }),
    [api.viewerIsAdmin],
  );

  return (
    <PageContext.Provider value={pageContext}>
      <Page>
        <Navbar>
          <Navbar.Brand>Annotation Cloud</Navbar.Brand>
          <Layout grow className="overflow-visible">
            {header}
          </Layout>
          <Dropdown>
            <Dropdown.Toggle
              id="help-menu"
              as={Navbar.Button}
              className={styles.button}
            >
              <FormattedMessage id="appPage.helpMenu" defaultMessage="Help" />{' '}
              <DropdownIcon width="10" className={styles.arrow} />
            </Dropdown.Toggle>
            <Dropdown.Menu data-bni-id="HelpMenuDropdown">
              <Dropdown.Item href="mailto:annotator-support@butterflynetwork.com ">
                <FormattedMessage
                  id="appPage.support"
                  defaultMessage="Contact Support"
                />
              </Dropdown.Item>
              <Dropdown.Item
                target="_blank"
                href="https://docs.google.com/document/d/1qmhe1gMYVLEAJcOxcESW8C8pSXfJaql8Om3rqtygAB0"
              >
                <FormattedMessage
                  id="appPage.docs"
                  defaultMessage="Help Documentation"
                />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setShow(true)}>
                <FormattedMessage id="appPage.about" defaultMessage="About" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle
              id="user-menu"
              as={Navbar.Button}
              className={styles.button}
            >
              {viewer?.username || '—'}{' '}
              <DropdownIcon width="10" className={styles.arrow} />
            </Dropdown.Toggle>
            <Dropdown.Menu data-bni-id="HeaderUserMenuDropdown">
              <Dropdown.Item onClick={auth.logout}>
                <FormattedMessage
                  id="appPage.logout"
                  defaultMessage="Log Out"
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
        <Page.Container>
          {sidePanel && viewer && (
            <Page.SidePanel useDrawer={useDrawer}>
              <Nav>
                <Nav.Header>Annotator</Nav.Header>
                <ActiveLink as={Nav.Item} to={`/${viewer.username}`}>
                  Assignments
                </ActiveLink>

                {window.bflyConfig.ENFORCE_CONTRACTS && (
                  <ActiveLink
                    as={Nav.Item}
                    to={`/earnings/${viewer.username}`}
                  >
                    Earnings
                  </ActiveLink>
                )}

                {api.viewerIsAdmin && (
                  <>
                    <>
                      <Nav.Header>Admin</Nav.Header>
                      <ActiveLink as={Nav.Item} to="/-/admin/tasks">
                        Tasks
                      </ActiveLink>
                      <ActiveLink as={Nav.Item} to="/-/admin/review_tasks">
                        Review Tasks
                      </ActiveLink>
                      <ActiveLink as={Nav.Item} to="/-/admin/users">
                        Users
                      </ActiveLink>
                      <ActiveLink as={Nav.Item} to="/-/admin/projects">
                        Projects
                      </ActiveLink>
                      <ActiveLink as={Nav.Item} to="/-/admin/custom_earnings">
                        Custom Earnings
                      </ActiveLink>
                      <ActiveLink as={Nav.Item} to="/-/admin/expenses">
                        Expenses
                      </ActiveLink>
                    </>
                    <>
                      <Nav.Header>DLC</Nav.Header>
                      <ActiveLink
                        as={Nav.Item}
                        to="/-/admin/dlc/inference-results"
                      >
                        Inference Inspector{' '}
                        <span role="img" aria-label="detective-emoji">
                          🕵️‍♀
                        </span>
                      </ActiveLink>

                      <ActiveLink
                        as={Nav.Item}
                        to="/-/admin/dlc/inference-processes?deployed=true"
                      >
                        Processes
                      </ActiveLink>
                    </>
                  </>
                )}
              </Nav>
            </Page.SidePanel>
          )}
          <Page.Main>{children}</Page.Main>
        </Page.Container>
        <AboutModal show={show} onHide={onHide} backdrop />
      </Page>
    </PageContext.Provider>
  );
}

AppPage.getData = ({ context }) => context.api.getViewer();

export default AppPage;
