export function getContiguousIntervals(intervalAnnotations) {
  const intervals = new Map();
  const current = new Map();

  const addToCurrent = (id, idx) => current.set(id, { start: idx, end: idx });

  const moveToResult = (id) => {
    const newInterval = current.get(id);
    current.delete(id);
    const old = intervals.get(id);
    intervals.set(id, (old || []).concat(newInterval));
  };

  Object.entries(intervalAnnotations).forEach(([key, tagValues]) => {
    const frameIdx = +key;

    tagValues.forEach((tagValue) => {
      const interval = current.get(tagValue);

      if (!interval) {
        addToCurrent(tagValue, frameIdx);
        return;
      }

      if (interval.end + 1 === frameIdx) {
        interval.end = frameIdx;
      } else {
        moveToResult(tagValue);
        addToCurrent(tagValue, frameIdx);
      }
    });
  });

  Array.from(current.keys(), moveToResult);

  return intervals;
}

export function pointerToFrameIndex({ currentTarget, pageX }, count) {
  const { left, right } = currentTarget.getBoundingClientRect();
  const range = Math.abs(left - right);
  const offsetX = (pageX - left) / range;

  const frameIndex = Math.min(
    count - 1,
    Math.max(0, Math.floor(offsetX * count)),
  );

  return frameIndex;
}
