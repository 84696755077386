import React from 'react';
import Form from '@bfly/ui/Form';

import { Calculation } from '../schema/AnnotationTask';
import AccordionFieldArray from './AccordionFieldArray';

const defaultCalculation = Calculation.default();

function CalculationsForm() {
  return (
    <Form.FieldSet legend="Calculations">
      <AccordionFieldArray<Calculation>
        name="definition.calculations"
        label="Calculations"
        labelSrOnly
        defaultValue={defaultCalculation}
      >
        {({ value, helpers }) =>
          value.map((item, idx) => {
            const name = `definition.calculations[${idx}]`;

            return (
              <AccordionFieldArray.Item
                key={name}
                name={name}
                onRemove={helpers.remove(item)}
                title={
                  item && item.displayText
                    ? `${idx + 1}. ${item.displayText}`
                    : 'New Calculation'
                }
              >
                <Form.FieldGroup name="displayText" label="Display Text" />

                <Form.FieldGroup name="calculationId" label="Calculation ID" />

                <Form.FieldGroup
                  name="calculationType"
                  label="Calculation Type"
                  className="mb-4"
                />

                <Form.FieldGroup name="visible" label="Visible" />
              </AccordionFieldArray.Item>
            );
          })
        }
      </AccordionFieldArray>
    </Form.FieldSet>
  );
}

export default CalculationsForm;
