import React, { useState } from 'react';
import Button from '@bfly/ui/Button';
import Form from '@bfly/ui/Form';
import Header from '@bfly/ui/Header';
import Modal from '@bfly/ui/Modal';

import { useShortcut } from './KeyboardShortcutManager';
import { QuestionField } from './QuestionsFieldset';

const propTypes = {};

function SkipButtonImpl({ skip, value: skipped, onChange }) {
  const [show, setShow] = useState(false);

  const handler = () => {
    onChange(!skipped);
    setShow(true);
  };

  useShortcut('Escape', handler);

  const onHide = () => setShow(false);
  return (
    <>
      <Header.Action onClick={handler}>Skip</Header.Action>
      <Modal show={show} onHide={onHide} scrollable={false}>
        <Modal.Header>
          <Modal.Title>Skip</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{skip.displayText}</p>
          {skip.question && (
            <QuestionField name="skipAnswer" question={skip.question} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Modal.ButtonGroup>
            <Form.Submit>Skip</Form.Submit>
            <Button
              variant="secondary"
              onClick={() => {
                onChange(false);
                onHide();
              }}
            >
              Cancel
            </Button>
          </Modal.ButtonGroup>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function SkipButton({ skip }) {
  return (
    <Form.Field name="skipped">
      {({ value, onChange }) => (
        <SkipButtonImpl skip={skip} value={value} onChange={onChange} />
      )}
    </Form.Field>
  );
}

SkipButton.propTypes = propTypes;

export default SkipButton;
