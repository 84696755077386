import { stylesheet } from 'astroturf';
import toString from 'lodash/toString';
import React, { useMemo } from 'react';
import FormCheck from '@bfly/ui/FormCheck';
import MainContent from '@bfly/ui/MainContent';
import Table from '@bfly/ui/Table';

import { useApi } from 'src/components/AuthProvider';
import CinePlayer from 'src/components/CinePlayer';
import FrameScrubber from 'src/components/FrameScrubber';

const styles = stylesheet`
  @import '~@bfly/ui/styles/theme';

  .cine {
    position: sticky;
    top: 5rem;
  }

  .head {
    composes: cine;
    top: 6rem;
    padding: 0.5rem 1rem;
    background-color: $bg-color;
    z-index: 1;
  }

  .action {
    flex: 1 1 0;
  }

  .username {
    flex: 1.5 1 0;
  }

  .annotation {
    flex: 3 1 0;
  }

  .created-at {
    flex: 1.5 1 0;
  }
`;

function LabelRow({ label, task, onChange, isDisabled }) {
  const annotations = useMemo(
    () =>
      task.questions
        ? task.questions
            .filter((question) => {
              const answer = label.answers[question.questionId];
              return !(
                answer == null ||
                (Array.isArray(answer) && !answer.length)
              );
            })
            .map((question) => ({
              ...question,
              annotation: toString(label.answers[question.questionId]),
            }))
        : [],
    [task, label],
  );

  return (
    <Table.Row>
      <Table.Cell className={styles.action}>
        <FormCheck name="enabled" checked={!isDisabled} onChange={onChange} />
      </Table.Cell>
      <Table.Cell className={styles.username}>{label.annotatorId}</Table.Cell>
      <Table.Cell className={styles.createdAt}>
        {new Date(label.annotatedOn).toLocaleDateString('en-US')}
      </Table.Cell>
      <Table.Cell
        direction="column"
        align="flex-start"
        justify="center"
        className={styles.annotation}
      >
        {annotations.map((question) => (
          <div key={question.questionId}>
            {question.displayText}: {question.annotation}
          </div>
        ))}
      </Table.Cell>
    </Table.Row>
  );
}

function CineReview({
  task,
  labels,
  images,
  file,
  compImages,
  compFile,
  disabled,
  onChangeDisabled,
}) {
  const api = useApi();

  const sortedLabels = useMemo(
    () => labels.sort((a, b) => b.enabled - a.enabled),
    [labels],
  );

  return (
    <>
      <MainContent size="large" className={styles.cine}>
        <CinePlayer
          api={api}
          file={file}
          frames={images}
          secondaryFile={compFile}
          secondaryFrames={compImages}
          renderFrameScrubber={({
            frameIndex,
            onSelectIndex,
            onTogglePlayback,
          }) => (
            <FrameScrubber
              readOnly
              frameIndex={frameIndex}
              frameCount={Math.max(images.length, compImages?.length ?? 0)}
              activeIndex={frameIndex}
              onSelectIndex={onSelectIndex}
              onTogglePlayback={onTogglePlayback}
              tracesDefinition={task.definition.traces || []}
              intervalsDefinition={task.definition.intervals}
            />
          )}
        />
      </MainContent>
      <Table className="m-3">
        <Table.Row className={styles.head}>
          <Table.Header className={styles.action}>Enabled</Table.Header>
          <Table.Header className={styles.username}>Username</Table.Header>
          <Table.Header className={styles.createdAt}>Created At</Table.Header>
          <Table.Header className={styles.annotation}>
            Annotations
          </Table.Header>
        </Table.Row>
        {sortedLabels.map((label) => (
          <LabelRow
            key={label.assignmentId}
            label={label}
            task={task.definition}
            onChange={() => onChangeDisabled(label.assignmentId)}
            isDisabled={disabled.has(label.assignmentId)}
          />
        ))}
      </Table>
    </>
  );
}

export default CineReview;
