/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { stylesheet } from 'astroturf';
import classNames from 'classnames';
import React from 'react';

const styles = stylesheet`
  @import '~@bfly/ui/styles/theme';

  .item-base {
    display: block;
    flex: none;
  }

  .item {
    composes: item-base;

    border: 1px solid transparent;

    cursor: pointer;

    &:hover {
      border-color: theme-color('primary');
    }

    &.active {
      border-color: theme-color('primary');
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .placeholder {
    composes: item-base;

    background: rgba($grey-5, 0.02);
  }
`;

class PreviewListItem extends React.Component {
  onClick = () => {
    const { onSelect, eventKey } = this.props;
    if (!onSelect) return;

    onSelect(eventKey);
  };

  render() {
    const { active, className, index, image, size } = this.props;

    return (
      <div
        role="button"
        onClick={this.onClick}
        style={{ width: size, height: size }}
        className={classNames(className, styles.item, active && styles.active)}
      >
        <img src={image.src} alt={`Preview ${index + 1}`} />
      </div>
    );
  }
}

const PreviewListItemPlaceholder = ({ size, className }) => (
  <div
    className={classNames(className, styles.placeholder)}
    style={{ width: size, height: size }}
  />
);

PreviewListItem.Placeholder = PreviewListItemPlaceholder;

export default PreviewListItem;
