/* eslint-disable max-classes-per-file */
import HttpError from 'found/HttpError';
import camelCase from 'lodash/camelCase';

const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred.';

export class ErrorBase extends HttpError {
  constructor(rawResponse, message) {
    super(rawResponse.status);
    this.message = message;
    this.rawResponse = rawResponse;
  }
}

export class UpstreamError extends ErrorBase {}

export class ClientError extends ErrorBase {}

export class InvalidInputError extends ClientError {
  constructor(rawResponse, fields) {
    super(rawResponse, fields[0].message);
    this.fields = fields;
  }
}

export class IdempotencyError extends ErrorBase {
  constructor(rawResponse, message, existingId) {
    super(rawResponse, message);

    this.existingId = existingId;
  }
}

export class NotFoundError extends ClientError {}

async function getErrors(response) {
  const error = {};

  try {
    error.body = await response.text();
    error.errors = JSON.parse(error.body).errors || [];
  } catch (e) {
    error.errors = [];
  }

  return error;
}

export async function resolveError(response) {
  const { body, errors } = await getErrors(response);

  const { status } = response;
  const rawResponse = { status, body };

  if (status >= 500) {
    return new UpstreamError(rawResponse, DEFAULT_ERROR_MESSAGE);
  }

  if (!Array.isArray(errors)) {
    return new ClientError(rawResponse, DEFAULT_ERROR_MESSAGE);
  }

  const firstError = errors[0];
  const firstMessage =
    (firstError && firstError.detail) || DEFAULT_ERROR_MESSAGE;

  if (status === 404) {
    return new NotFoundError(rawResponse, firstMessage);
  }

  if (
    firstError &&
    firstError.code === 'invalid_data.idempotency_conflict' &&
    firstError.meta &&
    firstError.meta.id
  ) {
    return new IdempotencyError(rawResponse, firstMessage, firstError.meta.id);
  }

  if (status === 422 || status === 409) {
    const fields = errors
      .filter(
        ({ source }) =>
          source && source.pointer && source.pointer.startsWith('/data/'),
      )
      .map(({ source, detail }) => ({
        message: detail || 'invalid field',
        path: source.pointer.substr('/data/'.length).split('/').map(camelCase),
      }));

    if (fields.length > 0) {
      return new InvalidInputError(rawResponse, fields);
    }
  }

  return new ClientError(rawResponse, firstMessage);
}
