/* eslint-disable @typescript-eslint/naming-convention */
import { stylesheet } from 'astroturf';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '@bfly/ui/Modal';
import Button from '@bfly/ui/Button';
import Table from 'src/components/Table';
import executeWithErrorToast from 'src/utils/executeWithErrorToast';
import ToastContext, { ToastManager } from '@bfly/ui/ToastContext';

import { useApi } from 'src/components/AuthProvider';
import { formatAssignmentUploads } from '../utils/uploadedAssignmentCreation';
import { WorklistUploadRow } from 'src/models/Worklist';

const styles = stylesheet`
  @import '~@bfly/ui/styles/theme';

  .table {
    overflow: auto;
    max-height: 45rem;

    td,
    th {
      text-align: center !important;
      color: black !important;
    }
  }
`;

interface Props {
  fetchWorklists: () => void;
  hideModal: boolean;
  setHideModal: (boolean) => void;
  username: string;
  assignmentsToCreate: WorklistUploadRow[];
}

function WorklistUploadModal({
  fetchWorklists,
  hideModal,
  setHideModal,
  username,
  assignmentsToCreate,
}: Props) {
  const api = useApi();
  const toast = useContext<ToastManager | null>(ToastContext);

  const uploadWorklist = async (): Promise<any> => {
    const formattedAssignments = formatAssignmentUploads(assignmentsToCreate);
    setHideModal(true);

    toast!.info('Uploading worklist...');

    const result = await executeWithErrorToast(toast, () =>
      api.createUploadedWorklist(username, formattedAssignments),
    );

    toast!.success(
      `${assignmentsToCreate.length} assignments successfully created`,
    );

    fetchWorklists();
    return result;
  };

  return (
    <Modal show={hideModal} scrollable={false} variant="dark">
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="aboutModal.title"
            defaultMessage="Uploading Worklist"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.table}>
          <Table>
            <thead>
              <tr>
                <th>Worklist</th>
                <th>Username</th>
                <th>Task Name</th>
                <th>Primary Item</th>
                <th>Comparison Image</th>
                <th>Start Frame</th>
                <th>End Frame</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {assignmentsToCreate &&
                assignmentsToCreate.map((assignment, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={idx} className={styles.row}>
                    <td>{assignment.name}</td>
                    <td>{username}</td>
                    <td>{assignment.task_name}</td>
                    <td>
                      {assignment.image_id}
                      {assignment.image_cloud_url}
                      {assignment.dl_result_id}
                      {assignment.review_assignment_id}
                    </td>
                    <td>
                      {assignment.comparison_image_id}
                      {assignment.comparison_image_cloud_url}
                    </td>
                    <td>{assignment.start_frame || '-'}</td>
                    <td>{assignment.end_frame || '-'}</td>
                    <td>{assignment.notes || '-'}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button onClick={() => uploadWorklist()}>
            <FormattedMessage
              id="jsonModal.confirm"
              defaultMessage="Confirm"
            />
          </Button>
          <Button variant="danger" onClick={() => setHideModal(true)}>
            <FormattedMessage id="jsonModal.close" defaultMessage="Close" />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

export default WorklistUploadModal;
