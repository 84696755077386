import { stylesheet } from 'astroturf';
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Match } from 'found';
import Link from 'found/Link';
import isEmpty from 'lodash/isEmpty';
import ProgressBar from 'react-bootstrap/ProgressBar';
import BlankSlate from '@bfly/ui/BlankSlate';
import ToastContext, { ToastManager } from '@bfly/ui/ToastContext';

import WorklistUploadModal from './WorklistUploadModal';
import Table from 'src/components/Table';
import { useApi } from 'src/components/AuthProvider';
import { useCsvData } from 'src/hooks/useCsvData';
import UploadCsvInput from 'src/components/UploadCsvInput';
import { Worklist, deserialize } from 'src/schema/Worklist';
import executeWithErrorToast from 'src/utils/executeWithErrorToast';
import WorklistDownloadButton from 'src/components/WorklistDownloadButton';
import CurateWorklistModal from 'src/components/CurateWorklistModal';
import DuplicateWorklistModal from 'src/components/DuplicateWorklistModal';
import WorklistDropdownOptions from 'src/components/WorklistDropdownOptions';

const styles = stylesheet`
  .centered {
    text-align: center;
  }
`;

async function getData({ params, context }) {
  const { username } = params;
  const { api } = context;

  const worklists = await api
    .getWorklistsForUser({ username })
    .then((serializedWorklists) =>
      serializedWorklists.map((d) => deserialize(d)),
    );
  return {
    worklists,
    api,
  };
}

interface Props {
  match: Match;
  data: {
    worklists: Worklist[];
  };
}

function UserWorklistsPage({ data, match }: Props) {
  const api = useApi();
  const { worklists } = data;
  const {
    params: { username },
    context: { api: contextApi },
  } = match;
  const toast = useContext<ToastManager | null>(ToastContext);

  const [userWorklists, setUserWorklists] = useState(worklists);
  const [csv, setCsv] = useState<File | null>(null);
  const [hideModal, setHideModal] = useState(true);
  const [hideCurateModal, setHideCurateModal] = useState(true);
  const [hideDuplicateModal, setHideDuplicateModal] = useState(true);
  const [selectedWorklist, setSelectedWorklist] = useState<Worklist | null>(
    null,
  );

  useEffect(() => {
    document.title = `Worklists - ${username} - Butterfly`;
  });

  useEffect(() => {
    setHideModal(!csv);
  }, [csv]);

  const hasData = !isEmpty(userWorklists);

  const fetchWorklists = async () => {
    try {
      const refetchedWorklists = await contextApi
        .getWorklistsForUser({ username })
        .then((serializedWorklists) =>
          serializedWorklists.map((d) => deserialize(d)),
        );
      setUserWorklists(refetchedWorklists);
    } catch (e: any) {
      toast!.error(e.message);
    }
  };

  const handleDeleteWorklist = async (worklist: Worklist) => {
    await executeWithErrorToast(toast, async () => {
      await api.clearWorklist(worklist.id);
      await api.deleteWorklist(worklist.id);
    });
    toast!.success('Worklist deleted');
    setUserWorklists(
      userWorklists.filter((userWorklist) => worklist.id !== userWorklist.id),
    );
  };

  const handleClearWorklist = async (worklist: Worklist) => {
    await executeWithErrorToast(toast, () => api.clearWorklist(worklist.id));
    toast!.success('Worklist cleared');
    setUserWorklists(
      userWorklists.map((userWorklist) => {
        if (userWorklist.id === worklist.id) {
          // eslint-disable-next-line
          userWorklist = {
            ...userWorklist,
            completedRatio: 100,
            numAssignments: userWorklist.numCompletedAssignments,
          };
        }
        return userWorklist;
      }),
    );
  };

  const csvDataState = useCsvData({
    csv,
    papaParseOptions: useMemo(
      () => ({
        header: true,
        skipEmptyLines: 'greedy',
        transform: (value, column) => {
          const shouldBeInteger = ['start_frame', 'end_frame', 'priority'];

          if (shouldBeInteger.includes(column)) {
            return parseInt(value, 10);
          }
          return value;
        },
      }),
      [],
    ),
  });

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Task</th>
            <th className={styles.centered}>
              Last
              <br />
              Completed
            </th>
            <th className={styles.centered}>Completed</th>
            <th className={styles.centered}>Progress</th>
            <th style={{ textAlign: 'center' }} colSpan={2}>
              <UploadCsvInput onClick={setCsv}>+ Upload CSV</UploadCsvInput>
            </th>
          </tr>
        </thead>
        {hasData ? (
          <tbody>
            {userWorklists.map((worklist) => (
              <Fragment key={worklist.id}>
                <tr>
                  <td>
                    <Link
                      to={`/-/admin/users/${username}/worklists/${worklist.id}`}
                    >
                      {worklist.name}
                    </Link>
                  </td>

                  <td>{worklist.taskName}</td>

                  <td>{worklist.lastCompleted?.toLocaleDateString()}</td>

                  <td className={styles.centered}>
                    {worklist.numCompletedAssignments} /{' '}
                    {worklist.numAssignments}
                  </td>

                  <td className={styles.centered}>
                    <ProgressBar
                      now={worklist.completedRatio}
                      variant={
                        worklist.numCompletedAssignments ===
                        worklist.numAssignments
                          ? 'success'
                          : undefined
                      }
                    />
                  </td>

                  <td>
                    {worklist.numCompletedAssignments > 0 && (
                      <WorklistDownloadButton worklistId={worklist.id} />
                    )}
                  </td>

                  <td>
                    <WorklistDropdownOptions
                      worklist={worklist}
                      setSelectedWorklist={setSelectedWorklist}
                      setHideCurateModal={setHideCurateModal}
                      setHideDuplicateModal={setHideDuplicateModal}
                      handleClearWorklist={handleClearWorklist}
                      handleDeleteWorklist={handleDeleteWorklist}
                    />
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        ) : (
          <BlankSlate>
            <BlankSlate.Title>No Assigned Tasks</BlankSlate.Title>
          </BlankSlate>
        )}
      </Table>
      {selectedWorklist && (
        <>
          <CurateWorklistModal
            worklist={selectedWorklist}
            show={!hideCurateModal}
            setHideCurateModal={setHideCurateModal}
          />
          <DuplicateWorklistModal
            worklist={selectedWorklist}
            hideModal={!hideDuplicateModal}
            setHideModal={setHideDuplicateModal}
          />
        </>
      )}

      <WorklistUploadModal
        fetchWorklists={fetchWorklists}
        hideModal={!hideModal}
        setHideModal={setHideModal}
        username={username}
        assignmentsToCreate={csvDataState.assignments}
      />
    </>
  );
}

UserWorklistsPage.getData = getData;

export default UserWorklistsPage;
