import { stylesheet } from 'astroturf';
import Link from 'found/Link';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import store from 'store/dist/store.modern';
import Layout from '@4c/layout';
import Button from '@bfly/ui/Button';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';

import AppPage from 'src/components/AppPage';
import Table from 'src/components/Table';
import ProjectFilterDropdown from 'src/components/ProjectFilterDropdown';
import executeWithErrorToast from 'src/utils/executeWithErrorToast';
import { useApi } from 'src/components/AuthProvider';
import { useToast } from '@bfly/ui/ToastContext';

const styles = stylesheet`
  .home {
    text-align: center;
  }
`;

const propTypes = {
  data: PropTypes.object.isRequired,
};

export const PROJECT_STORE_KEY = '@bfly-label-collector/project';

const filterProject = (tasks, project) =>
  project ? tasks.filter((task) => project.tasks.includes(task.name)) : tasks;

async function getData({ context }) {
  const { api } = context;

  const initialProject = store.get(PROJECT_STORE_KEY, null);

  const [viewer, tasks, projects] = await Promise.all([
    AppPage.getData({ context }),
    api.getTasks(),
    api.getProjects(),
  ]);
  return { viewer, tasks, projects, initialProject };
}

function TaskListPage({ data }) {
  const api = useApi();
  const toast = useToast();

  const { viewer, projects, initialProject } = data;

  const [project, setProject] = useState(initialProject);
  const [tasks, setTasks] = useState(data.tasks);

  const deleteTask = async (name) => {
    await executeWithErrorToast(toast, () => api.deleteTask(name));
    setTasks((prevTasks) => prevTasks.filter((t) => t.name !== name));
  };

  useEffect(() => {
    document.title = `Tasks - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Header>
        <Header.Title>Tasks</Header.Title>
        <Header.Actions align="center">
          <Link as={Button} to="/-/admin/tasks/-/new">
            New
          </Link>
        </Header.Actions>
      </Header>
      <MainContent size="medium">
        {projects.length > 0 && (
          <Layout justify="flex-start">
            <ProjectFilterDropdown
              projects={projects}
              value={project && project.name}
              onChange={setProject}
            />
          </Layout>
        )}
        <Table>
          <thead>
            <tr>
              <th className={styles.home}>Task</th>
              <th className={styles.home}>Updated</th>
              <th className={styles.home} />
            </tr>
          </thead>
          <tbody>
            {filterProject(tasks, project).map(
              ({
                name,
                latest_version: { version, created_at: createdAt },
              }) => (
                <tr key={name}>
                  <td>
                    <Link to={`/-/admin/tasks/${name}`}>{name}</Link>
                    <div className="mt-2 text-monospace">v{version}</div>
                  </td>
                  <td className={styles.home}>
                    {new Date(createdAt).toLocaleString()}
                  </td>
                  <td>
                    <Button onClick={() => deleteTask(name)}>Delete</Button>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </Table>
      </MainContent>
    </AppPage>
  );
}

TaskListPage.propTypes = propTypes;
TaskListPage.getData = getData;

export default TaskListPage;
