import { stylesheet } from 'astroturf';
import React, { useMemo, useRef } from 'react';
import Form from '@bfly/ui/Form';
import { FormHandle } from 'react-formal/Form';

import { User } from '../models';
import Label, { deserialize, serialize } from '../schema/Label';
import { ReviewTask } from 'src/schema/ReviewAnnotationTask';
import { useApi } from './AuthProvider';
import { useShortcuts } from './KeyboardShortcutManager';
import QuestionsFieldset from './QuestionsFieldset';

const styles = stylesheet`
  .inline {
    margin: 0;

    :global(.form-group) {
      margin: 0;
    }
  }
`;

interface Props {
  // TODO: type these guys
  assignment: any;
  reviewLabel: any;
  task: ReviewTask;
  onSubmit: () => void;
  viewer: User;
}

function SubmitSystemTaskButton({
  assignment,
  reviewLabel,
  task,
  onSubmit,
  viewer,
}: Props) {
  const api = useApi();
  const formRef = useRef<FormHandle | null>(null);

  const defaultValue = useMemo(
    () => deserialize(reviewLabel || {}, task.definition, viewer),
    [reviewLabel, task, viewer],
  );

  useShortcuts({
    Enter: () => {
      formRef.current!.submit();
    },
  });

  async function submitForm(formValue) {
    if (reviewLabel) return;

    const serialized = serialize(formValue, task.definition);
    const annotations = serialized.results;

    const label = {
      annotations,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      assignment_id: assignment.assignment_id,
      version: task.version,
    };

    await api.saveLabel(assignment.assignment_id, label);

    onSubmit();
  }

  return (
    <Form
      as={React.Fragment}
      schema={Label}
      ref={formRef}
      submitForm={submitForm}
      onSubmit={onSubmit}
      defaultValue={defaultValue}
      formContext={{ task: task.definition }}
    >
      <QuestionsFieldset
        readOnly={!!reviewLabel}
        questions={task.definition.questions}
        legendSrOnly
        className={styles.inline}
      />

      <Form.Submit>Submit</Form.Submit>
    </Form>
  );
}

export default SubmitSystemTaskButton;
