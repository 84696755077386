import { Router } from 'found';
import React, { useContext, useEffect } from 'react';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';
import ToastContext from '@bfly/ui/ToastContext';

import AppPage from 'src/components/AppPage';
import { useApi } from 'src/components/AuthProvider';
import CustomEarningsForm from 'src/components/CustomEarningsForm';
import Page from 'src/components/Page';
import executeWithErrorToast from '../utils/executeWithErrorToast';
import { CustomEarnings } from '../models/CustomEarnings';
import { User } from '../models';

interface Props {
  router: Router;
  data: {
    viewer: User;
  };
}

function NewCustomEarningsPage({ data, router }: Props) {
  const { viewer } = data;
  const api = useApi();
  const toast = useContext(ToastContext);

  const submitForm = async (
    earnings: CustomEarnings,
  ): Promise<CustomEarnings> => {
    const result = await executeWithErrorToast(toast, () =>
      api.createCustomEarnings(earnings),
    );
    router.push('/-/admin/custom_earnings');
    return result;
  };

  useEffect(() => {
    document.title = `New Custom Earnings - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Page.Header backTo="/-/admin/users">
        <Header.Title>Custom Earnings</Header.Title>
      </Page.Header>
      <MainContent size="medium">
        <CustomEarningsForm submitForm={submitForm} />
      </MainContent>
    </AppPage>
  );
}

NewCustomEarningsPage.getData = async (args) => ({
  viewer: await AppPage.getData(args),
});

export default NewCustomEarningsPage;
