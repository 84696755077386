import { stylesheet } from 'astroturf';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useApi } from 'src/components/AuthProvider';
import FailureIcon from '@bfly/icons/Failure';
import Link from '@bfly/ui/Link';
import Table from '@bfly/ui/Table';
import LoadingIndicator from '@bfly/ui/LoadingIndicator';
import useEventListener from '@restart/hooks/useEventListener';

import debounce from 'lodash/debounce';
import useCursorPaginationHandler from 'src/utils/useCursorPaginationHandler';

const styles = stylesheet`
  @import '~@bfly/ui/styles/theme';

  .breadcrumb {
    margin-bottom: 1rem;
  }

  .rowNum {
    flex: 1 0 0;
    color: $white;
  }

  .taskName {
    flex: 4 0 0;
  }

  .info,
  .skipped {
    flex: 1 0 0;
  }

  .skipped {
    color: $grey-25;
  }

  .row:hover {
    cursor: pointer;
    background-color: $darker-grey;
  }
`;

const propTypes = {
  data: PropTypes.object.isRequired,
};

function getData({ params }) {
  return params;
}

function UserWorklistDetailPage({ data }) {
  const { id, username } = data;

  const api = useApi();

  const [labelsResultList, setLabelsResultList] = useState([]);

  const nextResults = useCursorPaginationHandler(
    (...args) => api.getLabels(...args),
    {
      username,
      worklistId: id,
    },
    [],
  );

  useEffect(() => {
    document.title = `Worklist Detail - Butterfly`;
  });

  const debouncedHandleScroll = useMemo(
    () =>
      debounce(() => {
        if (!nextResults.hasMore || nextResults.isLoading) {
          return;
        }
        if (
          window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight
        ) {
          setLabelsResultList(nextResults.accumulatedData);
          nextResults.getNext();
        }
      }, 200),
    [nextResults],
  );

  useEventListener(window, 'scroll', debouncedHandleScroll);

  return (
    <>
      <Table>
        <Table.Row>
          <Table.Header className={styles.rowNum}>Assignment</Table.Header>
          <Table.Header className={styles.taskName}>Task Name</Table.Header>
          <Table.Header className={styles.info}>Created At</Table.Header>
          <Table.Header className={styles.info}>Skipped</Table.Header>
        </Table.Row>
        {(nextResults.isLoading
          ? labelsResultList
          : nextResults.accumulatedData
        ).map(
          (
            {
              assignment,
              annotations,
              created_at: createdAt,
              assignment_id: assignmentId,
            },
            index,
          ) => (
            <Table.Row
              key={assignmentId}
              as={Link}
              className={styles.row}
              to={`/${assignment.username}/worklists/${assignment.worklist_id}/${assignment.assignment_id}`}
              target="__blank"
            >
              <Table.Cell className={styles.rowNum}>{index + 1}</Table.Cell>

              <Table.Cell className={styles.taskName}>
                {assignment.task}
              </Table.Cell>
              <Table.Cell className={styles.info}>
                {new Date(createdAt).toLocaleDateString({
                  date: 'medium',
                })}
              </Table.Cell>
              <Table.Cell className={styles.skipped}>
                {(!annotations || annotations.skipped) && <FailureIcon />}
              </Table.Cell>
            </Table.Row>
          ),
        )}
      </Table>
      {nextResults.isLoading && <LoadingIndicator />}
      {!nextResults.isLoading && !nextResults.hasMore && (
        <div className={styles.footer}>All results loaded</div>
      )}
    </>
  );
}

UserWorklistDetailPage.propTypes = propTypes;

UserWorklistDetailPage.getData = getData;

export default UserWorklistDetailPage;
