import Link from 'found/Link';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import BlankSlate from '@bfly/ui/BlankSlate';
import Button from '@bfly/ui/Button';
import MainContent from '@bfly/ui/MainContent';

import Page from './Page';

function NotFoundPage() {
  return (
    <Page.Container center>
      <MainContent centerText>
        <BlankSlate>
          <BlankSlate.Title>
            <FormattedMessage
              id="notFoundPage.title"
              defaultMessage="The page you are looking for cannot be found."
            />
          </BlankSlate.Title>
          <BlankSlate.Action as={Link} to="/">
            {({ href, onClick }) => (
              <Button
                href={href}
                size="large"
                variant="primary"
                onClick={onClick}
              >
                <FormattedMessage
                  id="notFoundPage.returnHome"
                  defaultMessage="Return Home"
                />
              </Button>
            )}
          </BlankSlate.Action>
        </BlankSlate>
      </MainContent>
    </Page.Container>
  );
}

export default NotFoundPage;
