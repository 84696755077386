import { stylesheet } from 'astroturf';
import React from 'react';
import { defineMessages } from 'react-intl';
import DropdownList from '@bfly/ui/DropdownList';

import { Task } from '../models';

const styles = stylesheet`
  .dropdown {
    min-width: 30rem;
  }
`;

type Props = {
  value: string | null;
  tasks: Task[];
  onChange: (t: Task | null) => void;
};

const dropdown = defineMessages({
  placeholder: {
    id: 'taskDropdown.placeholder',
    defaultMessage: 'Select a Task',
  },
});

function TasksDropdown({ value, onChange, tasks }: Props) {
  return (
    <DropdownList
      className={styles.dropdown}
      dataKey="name"
      textField="name"
      placeholder={dropdown.placeholder}
      value={value}
      onChange={(v) => onChange(v)}
      data={tasks}
    />
  );
}

export default TasksDropdown;
