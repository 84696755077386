import { Router } from 'found';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { object, string } from 'yup';
import Layout from '@4c/layout';
import DropdownList from '@bfly/ui/DropdownList';
import Form from '@bfly/ui/Form';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';
import BaseTable from '@bfly/ui/Table';
import ToastContext from '@bfly/ui/ToastContext';

import AppPage from 'src/components/AppPage';
import { useApi } from 'src/components/AuthProvider';
import Page from 'src/components/Page';
import Table from 'src/components/Table';
import { Preset, Project, Task, User } from '../models';
import executeWithErrorToast from '../utils/executeWithErrorToast';

type Props = {
  router: Router;
  data: {
    viewer: User;
    project: Project;
    tasks: Task[];
    presets: Preset[];
  };
};

const schema = object({
  name: string().default(''),
});

const schemaDefault = schema.default();

const taskDropdownPlaceholder = {
  id: 'project.task',
  defaultMessage: 'Task Name',
};

const presetDropdownPlaceholder = {
  id: 'project.preset',
  defaultMessage: 'Preset Name',
};

function TaskDropdown({ tasks, onChange, ...props }) {
  return (
    <DropdownList<Task>
      {...props}
      onChange={(t) => onChange(t.name)}
      variant="light"
      dataKey="name"
      textField="name"
      className="flex-fill"
      placeholder={taskDropdownPlaceholder}
      data={tasks}
    />
  );
}

function PresetsDropdown({ presets, onChange, ...props }) {
  return (
    <DropdownList<Preset>
      {...props}
      onChange={(p) => onChange(p.preset_id)}
      variant="light"
      dataKey="preset_id"
      textField="name"
      className="flex-fill"
      placeholder={presetDropdownPlaceholder}
      data={presets}
    />
  );
}

function ProjectItem({ name, onDelete }) {
  return (
    <tr>
      <td>{name}</td>
      <td>
        <BaseTable.Button onClick={() => onDelete(name)}>
          Delete
        </BaseTable.Button>
      </td>
    </tr>
  );
}

async function getData({ params, context }) {
  const { name } = params;
  const { api } = context;

  const [viewer, project, tasks, presets] = await Promise.all([
    AppPage.getData({ context }),
    api.getProject(name),
    api.getTasks(),
    api.getPresets(),
  ]);

  return { viewer, project, tasks, presets };
}

function ProjectPage({ data }: Props) {
  const { viewer } = data;
  const api = useApi();
  const toast = useContext(ToastContext);

  const [project, setProject] = useState(data.project);

  const tasks = useMemo(
    () => data.tasks.filter((task) => !project.tasks.includes(task.name)),
    [project, data.tasks],
  );

  const presets = useMemo(
    () =>
      data.presets
        .filter(
          (preset) =>
            !project.presets.find((p) => p.preset_id === preset.preset_id),
        )
        .sort((a, b) => a.name.localeCompare(b.name)),
    [project, data.presets],
  );

  const updateData = async () => {
    const updated = await api.getProject(project.name);
    setProject(updated);
  };

  const [tab, setTab] = useState('tasks');
  const [formValue, setFormValue] = useState(schemaDefault);

  const isTask = tab === 'tasks';

  const handleTabChange = (t) => {
    setFormValue(schemaDefault);
    setTab(t);
  };

  const handleSubmit = async ({ name }) => {
    /* eslint-disable */
    const payload = {
      project_name: project.name,
      task_name: name,
      preset_id: name,
    };
    /* eslint-enable */

    await executeWithErrorToast(toast, () =>
      isTask
        ? api.associateTasksWithProject(project.name, payload)
        : api.associatePresetsWithProject(project.name, payload),
    );

    await updateData();
    setFormValue(schemaDefault);
  };

  const handleDelete = async (item) => {
    await executeWithErrorToast(toast, () =>
      isTask
        ? api.dissociateTaskWithProject(project.name, item)
        : api.dissociatePresetWithProject(project.name, item),
    );
    await updateData();
  };

  useEffect(() => {
    document.title = `Project Detail - ${project.name} - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Page.Header backTo="/-/admin/projects">
        <Header.Title>Project: {project.name}</Header.Title>
      </Page.Header>

      <MainContent size="medium">
        <Nav variant="tabs" activeKey={tab} onSelect={handleTabChange}>
          <Nav.Item>
            <Nav.Link eventKey="tasks">Tasks</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="presets">Presets</Nav.Link>
          </Nav.Item>
        </Nav>

        <Form
          value={formValue}
          onChange={setFormValue}
          schema={schema as any}
          submitForm={handleSubmit}
          className="mb-5"
        >
          <Layout align="center">
            <Form.FieldGroup
              name="name"
              label={isTask ? 'Task Name' : 'Preset Name'}
              labelSrOnly
            >
              {(props) =>
                isTask ? (
                  <TaskDropdown {...props} tasks={tasks} />
                ) : (
                  <PresetsDropdown {...props} presets={presets} />
                )
              }
            </Form.FieldGroup>

            <Form.Submit className="ml-3">Add</Form.Submit>
          </Layout>
        </Form>

        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th />
            </tr>
          </thead>

          <tbody>
            {project[tab].map((name) =>
              isTask ? (
                <ProjectItem key={name} name={name} onDelete={handleDelete} />
              ) : (
                // This means we are dealing with a preset.
                <ProjectItem
                  key={name.preset_id}
                  name={name.name}
                  onDelete={() => handleDelete(name.preset_id)}
                />
              ),
            )}
          </tbody>
        </Table>
      </MainContent>
    </AppPage>
  );
}

ProjectPage.getData = getData;

export default ProjectPage;
