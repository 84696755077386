import React from 'react';
import { triggerCommand } from '@bfly/annotation-tools';
import Form from '@bfly/ui/Form';
import useGlobalListener from '@restart/hooks/useGlobalListener';
import { DlResultOutputs } from '../schema/DlResultOutputs';

import ColladaOverlayCine from './ColladaOverlayCine';
import TraceableCine from './TraceableCine';

const triggerWhenInvalid = ({ invalid }) => (invalid ? 'change' : null);

interface Props {
  intervalAnnotations?: object;
  renderObject?: string;
  dlResultOutputs?: DlResultOutputs;
}

function TracesCine(props: Props) {
  useGlobalListener('pointerup', (event) => {
    if (event.ctrlKey || event.metaKey) {
      // Making sure that we trigger the FINISH_OPEN_TRACE command only once for the active trace.
      setTimeout(() => triggerCommand('FINISH_OPEN_TRACE'), 50);
    }
  });
  return (
    <Form.Field name="traces" validateOn={triggerWhenInvalid}>
      {({ value, onChange }) =>
        // TODO - refactor this to be a general solution with task injecting components.
        props.renderObject ? (
          <ColladaOverlayCine
            {...props}
            traceAnnotations={value}
            onTrace={onChange}
          />
        ) : (
          <TraceableCine
            {...props}
            traceAnnotations={value}
            onTrace={onChange}
          />
        )
      }
    </Form.Field>
  );
}

export default TracesCine;
