import { Line } from '../schema/ProjectOverlays';

const CONE_APEX_OFFSET = 1.3; // cm, comes from Lung preset
const RIB_SPACE_ANGLE = 30.0; // degrees, comes from B-lines model

export function getPhysicalDeltas(file) {
  /**
   * Returns the physical sizes of the pixels in the X and Y directions.
   *
   * @param file - A files dictionary which contains file-level attributes.
   * @returns The x and y physical size in centimeters.
   */
  const region =
    file.file_metadata &&
    ((file.file_metadata.SequenceOfUltrasoundRegions &&
      file.file_metadata.SequenceOfUltrasoundRegions[0]) ||
      (file.file_metadata.SequenceofUltrasoundRegions &&
        file.file_metadata.SequenceofUltrasoundRegions[0]));

  if (!region) {
    return null;
  }

  const { PhysicalDeltaX: x, PhysicalDeltaY: y } = region;
  if (!x || !y) {
    return null;
  }

  return { x, y };
}

function getLength([[x0, y0], [x1, y1]], physicalDeltas) {
  const deltaX = (x1 - x0) * physicalDeltas.x;
  const deltaY = (y1 - y0) * physicalDeltas.y;
  return Math.sqrt(deltaX ** 2 + deltaY ** 2);
}

function degreesToRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

export async function getRibSpaceSectorCoords(
  imageSize: [number, number],
  physicalDeltaY: number,
  sectorOffsetFromBottom = 20,
  sectorTickLength = 25.0,
): Promise<Line> {
  /**
   * Get rib-space sector coordinates for B-lines.
   *
   * @param: imageSize - The size of the input image (height, width).
   * @param: physicalDeltaY - Conversion factor of cm per pixel in the image.
   * @param: sectorOffsetFromBottom - Distance from bottom of the image of
   * the sector be (pixels).
   * @param: sectorTickLength - Length of sector side ticks (pixels).
   * @returns: A length-4 array denoting the four coordinates of the
   * rib-space sector.
   */
  const { add, dotMultiply, multiply } = await import(
    /* webpackChunkName: "math-vendor" */
    'mathjs'
  );

  const [imageHeight, imageWidth] = imageSize;

  // convert cone apex offset to pixels
  const coneApexOffsetPix = CONE_APEX_OFFSET / physicalDeltaY;

  // convert rib-space angle to radians
  const ribSpaceAngleRad = degreesToRadians(RIB_SPACE_ANGLE);

  // distance along the vertical in pixels from the cone apex to the sector
  const depthToSectorFromCone =
    imageHeight + coneApexOffsetPix - sectorOffsetFromBottom;

  // width of the sector
  const widthOfSector =
    2 * depthToSectorFromCone * Math.tan(ribSpaceAngleRad / 2);

  // calculate coordinates of sector
  const sectorLeftEdgeCoord = [
    imageWidth / 2 - widthOfSector / 2,
    imageHeight - sectorOffsetFromBottom,
  ];

  const sectorRightEdgeCoord = [
    imageWidth / 2 + widthOfSector / 2,
    imageHeight - sectorOffsetFromBottom,
  ];

  const tickAngle = (Math.PI - ribSpaceAngleRad) / 2;
  const leftTickVector = multiply(sectorTickLength, [
    Math.cos(tickAngle),
    -Math.sin(tickAngle),
  ]);
  const rightTickVector = dotMultiply([-1, 1], leftTickVector);

  const sectorCoords = [
    add(sectorLeftEdgeCoord, leftTickVector),
    sectorLeftEdgeCoord,
    sectorRightEdgeCoord,
    add(sectorRightEdgeCoord, rightTickVector),
  ];

  return sectorCoords;
}

function getEllipseCircumference(height, width, physicalDeltas) {
  /**
   * Computes the physical circumference of the given ellipse.
   *
   * Definition from Ramanujan:
   *    https://en.wikipedia.org/wiki/Ellipse#Circumference
   *
   * @param height - The height of the ellipse.
   * @param width - The width of the ellipse.
   * @param physicalDeltas - A dictionary of the physical sizes of X and Y
   *     pixels.
   * @returns The circumfrence of the ellipse in centimeters.
   */
  let a;
  let b;
  if (height > width) {
    b = width * physicalDeltas.x;
    a = height * physicalDeltas.y;
  } else {
    a = width * physicalDeltas.x;
    b = height * physicalDeltas.y;
  }
  const circumference = ((a + b) / 2) * Math.PI;
  return Number(circumference.toFixed(2));
}

function teichholzVolume(lvid) {
  return (7 * lvid ** 3) / (2.4 + lvid);
}

export const CALCULATION_TYPES = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  length_cm: {
    name: 'Length (cm)',
    getValue(_api, file, traceAnnotations) {
      let length = 0;
      const lengthKeys = [
        'femur',
        'biparietal',
        'crown_rump',
        'transcerebellar',
      ];
      const physicalDeltas = getPhysicalDeltas(file);
      if (!physicalDeltas) {
        return null;
      }

      for (const key of lengthKeys) {
        if (traceAnnotations[key] && traceAnnotations[key].length) {
          length = getLength(traceAnnotations[key][0][1], physicalDeltas);
          break;
        }
      }

      if (length === 0) {
        return null;
      }
      return Number(length.toFixed(2));
    },
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  circumference_cm: {
    name: 'Circumference (cm)',
    getValue(_api, file, traceAnnotations) {
      let circumference = 0;
      const circumferenceKeys = ['abdomen', 'head'];
      const physicalDeltas = getPhysicalDeltas(file);
      if (!physicalDeltas) {
        return null;
      }

      for (const key of circumferenceKeys) {
        if (traceAnnotations[key] && traceAnnotations[key].length) {
          const height = traceAnnotations[key][0][1][2];
          const width = traceAnnotations[key][0][1][3];
          circumference = getEllipseCircumference(
            height,
            width,
            physicalDeltas,
          );
          break;
        }
      }

      if (circumference === 0) {
        return null;
      }

      return circumference;
    },
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  femur_length_gestational_age_hadlock: {
    // Gestational Age calculation from:
    //   https://www.ncbi.nlm.nih.gov/pubmed/6739822
    name: 'Hadlock Femur Length',
    getValue(_api, file, traceAnnotations) {
      const physicalDeltas = getPhysicalDeltas(file);
      if (!physicalDeltas) {
        return null;
      }

      if (!traceAnnotations.femur || !traceAnnotations.femur.length) {
        return null;
      }

      const femurLength = getLength(
        traceAnnotations.femur[0][1],
        physicalDeltas,
      );

      return Number(
        (10.35 + 2.46 * femurLength + 0.17 * femurLength ** 2).toFixed(2),
      );
    },
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  abdominal_circumference_gestational_age_hadlock: {
    // Gestational Age calculation from:
    //   https://www.ncbi.nlm.nih.gov/pubmed/6739822
    name: 'Hadlock Abdominal Circumference',
    getValue(_api, file, traceAnnotations) {
      const physicalDeltas = getPhysicalDeltas(file);
      if (!physicalDeltas) {
        return null;
      }
      if (!traceAnnotations.abdomen || !traceAnnotations.abdomen.length) {
        return null;
      }

      const height = traceAnnotations.abdomen[0][1][2];
      const width = traceAnnotations.abdomen[0][1][3];
      const circumference = getEllipseCircumference(
        height,
        width,
        physicalDeltas,
      );

      return Number(
        (8.14 + 0.753 * circumference + 0.0036 * circumference ** 2).toFixed(
          2,
        ),
      );
    },
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  head_circumference_gestational_age_hadlock: {
    // Gestational Age calculation from:
    //   https://www.ncbi.nlm.nih.gov/pubmed/6739822
    name: 'Hadlock Head Circumference',
    getValue(_api, file, traceAnnotations) {
      const physicalDeltas = getPhysicalDeltas(file);
      if (!physicalDeltas) {
        return null;
      }
      if (!traceAnnotations.head || !traceAnnotations.head.length) {
        return null;
      }

      const height = traceAnnotations.head[0][1][2];
      const width = traceAnnotations.head[0][1][3];
      const circumference = getEllipseCircumference(
        height,
        width,
        physicalDeltas,
      );

      return Number(
        (8.96 + 0.54 * circumference + 0.0003 * circumference ** 3).toFixed(2),
      );
    },
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  biparietal_diameter_gestational_age_hadlock: {
    // Gestational Age calculation from:
    //   https://www.ncbi.nlm.nih.gov/m/pubmed/6739822
    name: 'Hadlock Biparietal Diameter',
    getValue(_api, file, traceAnnotations) {
      const physicalDeltas = getPhysicalDeltas(file);
      if (!physicalDeltas) {
        return null;
      }

      if (
        !traceAnnotations.biparietal ||
        !traceAnnotations.biparietal.length
      ) {
        return null;
      }

      const bpdLength = getLength(
        traceAnnotations.biparietal[0][1],
        physicalDeltas,
      );

      return Number(
        (9.54 + 1.482 * bpdLength + 0.1676 * bpdLength ** 2).toFixed(2),
      );
    },
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  transverse_cerebellar_diameter_gestational_age_makhoul: {
    // Gestational Age calculation from:
    //   https://www.ncbi.nlm.nih.gov/pubmed/10914622
    name: 'Makhoul Transverse Cerebellar Diameter',
    getValue(_api, file, traceAnnotations) {
      const physicalDeltas = getPhysicalDeltas(file);
      if (!physicalDeltas) {
        return null;
      }

      if (
        !traceAnnotations.transcerebellar ||
        !traceAnnotations.transcerebellar.length
      ) {
        return null;
      }

      const tcdLength = getLength(
        traceAnnotations.transcerebellar[0][1],
        physicalDeltas,
      );

      return Number(((tcdLength - 0.279) / 0.142).toFixed(2));
    },
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  crown_rump_length_gestational_age_verberg: {
    // Gestational Age calculation from:
    //   https://www.ncbi.nlm.nih.gov/pubmed/18348183
    name: 'Verberg Crown Rump Length',
    getValue(_api, file, traceAnnotations) {
      const physicalDeltas = getPhysicalDeltas(file);
      if (!physicalDeltas) {
        return null;
      }

      if (
        !traceAnnotations.crown_rump ||
        !traceAnnotations.crown_rump.length
      ) {
        return null;
      }

      const crlLength =
        10 * getLength(traceAnnotations.crown_rump[0][1], physicalDeltas);

      return Number(
        Math.exp(
          1.4653 + 0.001737 * crlLength + 0.2313 * Math.log(crlLength),
        ).toFixed(2),
      );
    },
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ef_teichholz: {
    name: 'Teichholz EF',
    getValue(_api, file, traceAnnotations) {
      const physicalDeltas = getPhysicalDeltas(file);
      if (!physicalDeltas) {
        return null;
      }

      if (
        !traceAnnotations['LVID-ED'] ||
        !traceAnnotations['LVID-ED'].length ||
        !traceAnnotations['LVID-ES'] ||
        !traceAnnotations['LVID-ES'].length
      ) {
        return null;
      }

      const edVolume = teichholzVolume(
        getLength(traceAnnotations['LVID-ED'][0][1], physicalDeltas),
      );
      const esVolume = teichholzVolume(
        getLength(traceAnnotations['LVID-ES'][0][1], physicalDeltas),
      );

      return Number(((100 * (edVolume - esVolume)) / edVolume).toFixed(1));
    },
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ef_simpsons: {
    name: 'Simpsons EF',
    getValue(api, file, traceAnnotations) {
      if (
        !traceAnnotations['LV-ED'] ||
        !traceAnnotations['LV-ED'].length ||
        !traceAnnotations['LV-ES'] ||
        !traceAnnotations['LV-ES'].length
      ) {
        return null;
      }

      return api.getSimpsonsEf(file, traceAnnotations);
    },
  },
};

export function getCalculations(calculations, traceAnnotations, file, api) {
  if (!calculations || !calculations.length) return {};

  return Promise.all(
    calculations
      .filter((c) => CALCULATION_TYPES[c.calculationType])
      .map(async ({ calculationType, calculationId }) => [
        calculationId,
        await CALCULATION_TYPES[calculationType].getValue(
          api,
          file,
          traceAnnotations,
        ),
      ]),
  ).then((values) => {
    const results = {};
    values.forEach(([id, value]) => {
      results[id] = value;
    });
    return results;
  });
}
