import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import BlankSlate from '@bfly/ui/BlankSlate';
import Navbar from '@bfly/ui/Navbar';

import { useAuth } from 'src/components/AuthProvider';
import Page from 'src/components/Page';

const propTypes = {};

function LogoutPage() {
  const auth = useAuth();

  useEffect(() => {
    document.title = `Logged Out - Butterfly`;
  });

  // XXX: allows for a user to manually enter `/logout` and properly log out of the app.
  if (auth?.isAuthenticated()) {
    auth?.logout();
  }
  return (
    <Page>
      <Navbar />
      <Page.Container>
        <BlankSlate>
          <BlankSlate.Title>
            <FormattedMessage
              id="logout.title"
              defaultMessage="You Have Been logged Out"
            />
          </BlankSlate.Title>
          <BlankSlate.Body>
            To log back in click the button below
          </BlankSlate.Body>
          <BlankSlate.Action href="/login">Log in</BlankSlate.Action>
        </BlankSlate>
      </Page.Container>
    </Page>
  );
}

LogoutPage.propTypes = propTypes;

export default LogoutPage;
