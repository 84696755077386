import React, { useMemo, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Button from '@bfly/ui/Button';
import Form from '@bfly/ui/Form';
import Header from '@bfly/ui/Header';
import Modal from '@bfly/ui/Modal';
import { FormHandle } from 'react-formal/Form';

import { User } from '../models';
import Label, { deserialize, serialize } from '../schema/Label';
import { ReviewTask } from 'src/schema/ReviewAnnotationTask';
import { useApi } from './AuthProvider';
import { useShortcuts } from './KeyboardShortcutManager';
import QuestionsFieldset from './QuestionsFieldset';

interface Props {
  // TODO: type these guys
  assignment: any;
  reviewLabel: any;
  reviewTask: ReviewTask;
  onSubmit: () => void;
  viewer: User;
}

function SubmitReviewButton({
  assignment,
  reviewLabel,
  reviewTask,
  onSubmit,
  viewer,
}: Props) {
  const api = useApi();
  const formRef = useRef<FormHandle | null>(null);
  const [show, setShow] = useState(false);

  const onHide = () => setShow(false);

  const defaultValue = useMemo(
    () => deserialize(reviewLabel || {}, reviewTask.definition, viewer),
    [reviewLabel, reviewTask, viewer],
  );

  useShortcuts({
    Enter: () => {
      if (show) formRef.current!.submit();
      else setShow(true);
    },
  });

  async function submitForm(formValue) {
    if (reviewLabel) return;

    const serialized = serialize(formValue, reviewTask.definition);
    const annotations = serialized.results;

    const label = {
      annotations,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      assignment_id: assignment.assignment_id,
      version: reviewTask.version,
    };

    await api.saveLabel(assignment.assignment_id, label);

    onSubmit();
  }

  return (
    <>
      <Header.Action onClick={() => setShow(true)}>
        Review and Submit
      </Header.Action>
      <Modal show={show} onHide={onHide} scrollable={false}>
        <Form
          as={React.Fragment}
          schema={Label}
          ref={formRef}
          submitForm={submitForm}
          onSubmit={onSubmit}
          defaultValue={defaultValue}
          formContext={{ task: reviewTask.definition }}
        >
          <>
            <Modal.Header>
              <Modal.Title>Submit Review</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{reviewTask.definition.displayText}</p>
              {reviewTask.definition.instructions && (
                <ReactMarkdown>
                  {reviewTask.definition.instructions}
                </ReactMarkdown>
              )}
              <QuestionsFieldset
                readOnly={!!reviewLabel}
                questions={reviewTask.definition.questions}
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.ButtonGroup>
                <Form.Submit>Submit</Form.Submit>
                <Button variant="secondary" onClick={onHide}>
                  Cancel
                </Button>
              </Modal.ButtonGroup>
            </Modal.Footer>
          </>
        </Form>
      </Modal>
    </>
  );
}

export default SubmitReviewButton;
