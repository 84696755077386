import { stylesheet } from 'astroturf';
import Link from 'found/Link';
import React, { useEffect } from 'react';
import Button from '@bfly/ui/Button';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';

import AppPage from 'src/components/AppPage';
import Table from 'src/components/Table';
import { Project, User } from '../models';

const styles = stylesheet`
  .home {
    text-align: center;
  }
`;

type Props = {
  data: {
    viewer: User;
    projects: Project[];
  };
};

async function getData({ context }) {
  const { api } = context;
  const [viewer, projects] = await Promise.all([
    AppPage.getData({ context }),
    api.getProjects(),
  ]);
  return { viewer, projects };
}

function ProjectListPage({ data }: Props) {
  const { viewer, projects } = data;

  useEffect(() => {
    document.title = `Projects - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Header>
        <Header.Title>Projects</Header.Title>
        <Header.Actions align="center">
          <Link as={Button} to="/-/admin/projects/-/new">
            New
          </Link>
        </Header.Actions>
      </Header>
      <MainContent size="medium">
        <Table>
          <thead>
            <tr>
              <th className={styles.home}>Project</th>
              <th className={styles.home}>Presets</th>
              <th className={styles.home}>Tasks</th>
            </tr>
          </thead>
          <tbody>
            {projects.map(({ name, presets, tasks }) => (
              <tr key={name}>
                <td>
                  <Link to={`/-/admin/projects/${name}`}>{name}</Link>
                </td>
                <td className={styles.home}>{presets.length}</td>
                <td className={styles.home}>{tasks.length}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </MainContent>
    </AppPage>
  );
}

ProjectListPage.getData = getData;

export default ProjectListPage;
