import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import store from 'store/dist/store.modern';

import { useApi } from './AuthProvider';
import { useShortcut } from './KeyboardShortcutManager';
import Lightbox from './Lightbox';

const KEY = '@bfly-label-collector/instructions:';

function InstructionsLightbox({ username, task, readOnly, ...props }) {
  const seenKey = `${KEY} ${username},${task.name}`;

  const defaultShow = !store.get(seenKey, readOnly);

  const [show, setShow] = useState(defaultShow);

  const api = useApi();

  const images = task.referenceImages.map((img) => ({
    ...img,
    src: api.getTaskReferenceImageUrl(img.id),
  }));

  const {
    definition: { instructions, instructionsVimeoId },
  } = task;

  const onHide = () => {
    setShow(false);
    store.set(seenKey, true);
  };

  useShortcut({
    '-': () => {
      if (show) onHide();
      else setShow(true);
    },
  });

  return (
    <Lightbox
      {...props}
      images={images}
      instructionsVimeoId={instructionsVimeoId}
      onHide={onHide}
      show={show}
    >
      <div className="p-5">
        <ReactMarkdown>{instructions}</ReactMarkdown>
      </div>
    </Lightbox>
  );
}

export default InstructionsLightbox;
