import { stylesheet } from 'astroturf';
import chroma from 'chroma-js';
import noop from 'lodash/noop';
import uniqBy from 'lodash/uniqBy';
import React, { useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';
import Layout from '@4c/layout';
import { PathTool } from '@bfly/annotation-tools';
import Button from '@bfly/ui/Button';
import DropdownList from '@bfly/ui/DropdownList';

import { useApi } from 'src/components/AuthProvider';
import Calculations from 'src/components/Calculations';
import CinePlayer from 'src/components/CinePlayer';
import FrameScrubber from 'src/components/FrameScrubber';
import { useShortcuts } from 'src/components/KeyboardShortcutManager';
import AnnotationTask from '../schema/AnnotationTask';
import Label from '../schema/Label';

const styles = stylesheet`
  .container {
    position: relative;
  }

  .selected-info {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.5rem;
  }

  .dropdown {
    min-width: 30rem;
  }
`;

const messages = defineMessages({
  usernameFilter: {
    id: 'labelsReview.username',
    defaultMessage: 'Filter by User',
  },
});

function getFrameValue(label, trace, frameIndex) {
  const { traces } = label;

  const values = traces[trace.traceId];
  if (!values || !values.length) {
    return undefined;
  }

  const nextValueIndex = values.findIndex(
    ([otherFrameIndex]) => otherFrameIndex >= frameIndex,
  );

  const nextValue = values[nextValueIndex];
  if (!nextValue) {
    return undefined;
  }

  const [nextFrameIndex, nextPoints] = nextValue;
  if (nextFrameIndex === frameIndex) {
    return nextPoints;
  }

  if (!trace.interpolate || !nextPoints) {
    return undefined;
  }

  const prevValue = values[nextValueIndex - 1];
  if (!prevValue) {
    return undefined;
  }

  const [prevFrameIndex, prevPoints] = prevValue;
  if (!prevPoints) {
    return undefined;
  }

  const prevFrameDelta = frameIndex - prevFrameIndex;
  const totalFrameDelta = nextFrameIndex - prevFrameIndex;
  const nextFrameWeight = prevFrameDelta / totalFrameDelta;
  const prevFrameWeight = 1 - nextFrameWeight;

  const points = prevPoints.map((prevPoint, i) => {
    const nextPoint = nextPoints[i];
    return [
      prevFrameWeight * prevPoint[0] + nextFrameWeight * nextPoint[0],
      prevFrameWeight * prevPoint[1] + nextFrameWeight * nextPoint[1],
    ];
  });

  points.interpolated = true;

  return points;
}

interface FrameResult {
  assignmentId: string;
  username: string;
  value: Array<[number, number]> | undefined;
}

interface FrameResults {
  [traceId: string]: {
    [frameIndex: number]: FrameResult[];
  };
}

interface FrameWithTraces {
  [traceId: string]: Array<[number]>;
}
/**
 * Merges each label result by trace and frame index.
 *
 * This is similar to TraceableCine's getFrameResults but with the additional
 * nesting for multiple labels.
 *
 * Generally, the Cine component is expecting a single trace result
 * (structured as { traceId: [ [frameIndex, value] ] }) so we also do the mapping
 * from this nested structure to a simplified "single" trace result. this is done
 * in order to to display trace results on the FrameScrubber.
 *
 * @param task - the task
 * @param labels - a list of labels with annotations
 *
 * @returns [results, framesWithTraces] -
 *    results is the nested structure
 *    framesWithTraces is the simplified "single" trace result
 */
function getFrameResults(task, labels): [FrameResults, FrameWithTraces] {
  const results = {};
  const framesWithTraces = {};

  task.definition.traces.forEach((trace) => {
    const id = trace.traceId;
    results[id] = {};

    labels
      .filter((label) => !!label.traces[id])
      .forEach((label) => {
        label.traces[id].forEach(([relativeFrameIndex]) => {
          const frameIndex = relativeFrameIndex + (label.startFrame || 0);

          if (!results[id][frameIndex]) {
            results[id][frameIndex] = [];
          }

          results[id][frameIndex].push({
            assignmentId: label.assignmentId,
            username: label.annotatorId,
            value: getFrameValue(label, trace, relativeFrameIndex),
          });
        });
      });

    /**
     * FrameScrubber expects the trace structure as { traceId: [ [frameIndex, _value] ] }
     * and it ignores the value piece.
     * so we take the frame indices calculated above and map it to the expected structure.
     */
    framesWithTraces[id] = Object.keys(results[id]).map((idx) => [idx]);
  });

  return [results, framesWithTraces];
}

function useFilterTraces(frameIndex, results, usernameFilter) {
  return useMemo(() => {
    if (results && results[frameIndex]) {
      const filtered = results[frameIndex].filter(
        (result) =>
          usernameFilter === null ||
          result.username === usernameFilter.username,
      );

      return [results[frameIndex], filtered];
    }

    return [[], []];
  }, [frameIndex, results, usernameFilter]);
}

interface Props {
  task: AnnotationTask;
  file: any;
  labels: Label[];
  images: any[];
  activeTraceId: string;
  onDisable: (assignmentId: string) => void;
  disabledTraces: Set<string>;
}

function TracesReviewCine({
  file,
  task,
  labels,
  images,
  activeTraceId,
  onDisable,
  disabledTraces,
}: Props) {
  const api = useApi();
  const [paused, setPaused] = useState(true);
  const [frameIndex, setFrameIndex] = useState(0);
  const [selectedTraceIndex, setSelectedTraceIndex] = useState<number | null>(
    null,
  );
  const [usernameFilter, setUsernameFilter] = useState<{
    username: string;
  } | null>(null);

  const [frameResults, framesWithTraces] = useMemo(
    () => getFrameResults(task, labels),
    [task, labels],
  );

  const [traces, filteredTraces] = useFilterTraces(
    frameIndex,
    frameResults[activeTraceId],
    usernameFilter,
  );

  const uniqueUsernames = useMemo(() => uniqBy(traces, 'username'), [traces]);

  const hasSelected =
    selectedTraceIndex !== null && !!filteredTraces[selectedTraceIndex];

  const colorScale = useMemo(
    () =>
      chroma
        .scale(['#ffa726', '#2779ff'])
        .domain([0, traces.length - 1])
        .mode('lch'),
    [traces],
  );

  const handleSelectFrame = (frame) => {
    setFrameIndex(frame);
    setSelectedTraceIndex(null);
    setUsernameFilter(null);
  };

  const handleSelect = (idx) => {
    setSelectedTraceIndex((s) => (s === idx ? null : idx));
  };

  const handleNext = () => {
    if (!filteredTraces.length) {
      return;
    }

    setSelectedTraceIndex((s) =>
      s == null || s === filteredTraces.length - 1 ? 0 : s + 1,
    );
  };

  const handlePrev = () => {
    if (!filteredTraces.length) {
      return;
    }

    setSelectedTraceIndex((s) => (!s ? filteredTraces.length - 1 : s - 1));
  };

  const handleDisable = () => {
    if (hasSelected) {
      onDisable(filteredTraces[selectedTraceIndex!].assignmentId);
    }
  };

  const handleSelectUsername = (username) => {
    setUsernameFilter(username);
    setSelectedTraceIndex(null);
  };

  useShortcuts({
    ArrowUp: handleNext,
    ArrowDown: handlePrev,
  });

  return (
    <>
      <Layout className="mb-3" justify="space-between" align="center" pad>
        <DropdownList
          className={styles.dropdown}
          data={uniqueUsernames}
          textField="username"
          dataKey="assignmentId"
          value={usernameFilter}
          onChange={handleSelectUsername}
          placeholder={messages.usernameFilter}
          disabled={traces.length === 0}
          allowEmpty
        />

        <Button disabled={!hasSelected} onClick={handleDisable}>
          {hasSelected &&
          disabledTraces.has(filteredTraces[selectedTraceIndex!].assignmentId)
            ? 'Enable'
            : 'Disable'}{' '}
          Selected Trace
        </Button>
      </Layout>
      <div className={styles.container}>
        <CinePlayer
          api={api}
          file={file}
          frames={images}
          paused={paused}
          frameIndex={frameIndex}
          onSelectFrame={handleSelectFrame}
          onTogglePlayback={setPaused}
          renderFrameScrubber={({
            frameIndex: index,
            onSelectIndex,
            onTogglePlayback,
          }) => (
            <FrameScrubber
              readOnly
              frameIndex={index}
              frameCount={images.length}
              activeIndex={index}
              onSelectIndex={onSelectIndex}
              onTogglePlayback={onTogglePlayback}
              tracesDefinition={task.definition.traces || []}
              intervalsDefinition={task.definition.intervals}
              traceAnnotations={framesWithTraces}
            />
          )}
          renderIndicator={() =>
            task && (
              <Calculations
                file={file}
                calculations={task.definition.calculations}
                traceAnnotations={framesWithTraces}
              />
            )
          }
          renderFrameOverlay={() => (
            <>
              {filteredTraces
                .filter((_, idx) => idx !== selectedTraceIndex)
                .map((result, idx) => {
                  const isDisabled = disabledTraces.has(result.assignmentId);
                  const color = colorScale(idx)
                    .alpha(selectedTraceIndex !== null ? 0.2 : 1)
                    .hex();

                  return (
                    <PathTool
                      key={result.assignmentId}
                      disabled
                      isClosed
                      data={result.value}
                      color={color}
                      onTrace={noop}
                      dash={[2, 2]}
                      dashEnabled={isDisabled}
                    />
                  );
                })}

              {/* Render the selected trace in front of all other drawings */}
              {hasSelected && (
                <PathTool
                  disabled
                  isClosed
                  data={filteredTraces[selectedTraceIndex!].value}
                  color={colorScale(selectedTraceIndex).hex()}
                  onTrace={noop}
                  onSelect={() => handleSelect(selectedTraceIndex)}
                  strokeWidth={3}
                  dash={[2, 2]}
                  dashEnabled={disabledTraces.has(
                    filteredTraces[selectedTraceIndex!].assignmentId,
                  )}
                />
              )}
            </>
          )}
        />

        {hasSelected && (
          <small className={styles.selectedInfo}>
            <dl>
              <dt>Assignment ID</dt>
              <dd>{filteredTraces[selectedTraceIndex!].assignmentId}</dd>
              <dt>Username</dt>
              <dd>{filteredTraces[selectedTraceIndex!].username}</dd>
            </dl>
          </small>
        )}
      </div>
    </>
  );
}

export default TracesReviewCine;
