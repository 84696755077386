import { stylesheet } from 'astroturf';
import classNames from 'classnames';
import React from 'react';
import Button from '@bfly/ui/Button';

const styles = stylesheet`
  @import '~@bfly/ui/styles/theme';

  .btn {
    justify-content: flex-start;
    min-height: $button-height;
    border: 1px solid transparent;
    border-radius: $button-border-radius;
    white-space: initial;
    text-align: left;
    padding: 0 $button-padding-x 0 ($button-padding-x / 2);
  }

  .active {
    border: 1px solid var(--theme-link-color, white);
  }
`;

const propTypes = {};

function TagButton({ active, className, ...props }) {
  return (
    <Button
      theme="link"
      active={active}
      className={classNames(className, styles.btn, !!active && styles.active)}
      {...props}
    />
  );
}

TagButton.propTypes = propTypes;

export default TagButton;
