import { stylesheet } from 'astroturf';
import { Match } from 'found';
import Link from 'found/Link';
import React, { useEffect } from 'react';
import { FormattedDate } from 'react-intl';
import Layout from '@4c/layout';
import Button from '@bfly/ui/Button';
import Card from '@bfly/ui/Card';
import Header from '@bfly/ui/Header';
import Heading from '@bfly/ui/Heading';
import MainContent from '@bfly/ui/MainContent';
import Text from '@bfly/ui/Text';

import AppPage from 'src/components/AppPage';
import EarningSummary from 'src/components/EarningSummary';
import FormattedCurrency from 'src/components/FormattedCurrency';
import Table from 'src/components/Table';
import { User } from '../models';
import { Earning, deserializeEarnings } from '../schema/Earning';
import { formatForRoute } from '../utils/date';

const styles = stylesheet`
  @import '~@bfly/ui/styles/theme';

  .summary-card {
    max-width: 35rem;
  }
`;

async function getData({ params, context }) {
  const { username } = params;
  const { api } = context;

  const [viewer, { earnings, latest }] = await Promise.all([
    AppPage.getData({ context }),
    api.getEarnings({ username }).then(deserializeEarnings),
  ]);

  return {
    viewer,
    latest,
    earnings,
  };
}

interface Props {
  match: Match;
  data: {
    viewer: User;
    earnings: Earning[];
    latest: Earning;
  };
}

function EarningsPage({ data, match }: Props) {
  const { viewer, earnings, latest } = data;
  const { username } = match.params;

  useEffect(() => {
    document.title = `${
      username === viewer.username ? 'Earnings' : `Earnings for ${username}`
    } - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Header>
        <Header.Title>
          {username === viewer.username
            ? 'Earnings'
            : `Earnings for ${username}`}
        </Header.Title>
      </Header>

      <MainContent size="medium">
        <Layout pad={7} direction="column">
          {latest && (
            <Layout direction="column">
              <Heading>Upcoming Earnings Amount</Heading>
              <Card className={styles.summaryCard}>
                <Card.Header variant="body" color="body">
                  Total
                </Card.Header>
                <Card.Body>
                  <Layout pad={3} direction="column">
                    <Text variant="lg" color="headline">
                      <FormattedCurrency value={latest.totalAmount} />
                    </Text>
                    {latest.startDate && (
                      <Text>
                        <p>
                          Amount due since{' '}
                          <FormattedDate value={latest.startDate} />
                        </p>
                      </Text>
                    )}
                    <Text>
                      <Link
                        variant="secondary"
                        as={Button}
                        to={`/earnings/${username}/${formatForRoute(
                          latest.startDate,
                        )}`}
                      >
                        View
                      </Link>
                    </Text>
                  </Layout>
                </Card.Body>
              </Card>
            </Layout>
          )}

          <Layout direction="column">
            <Heading>Past Earnings</Heading>
            <Table variant="styled-header">
              <thead>
                <tr>
                  <th>Earning</th>
                  <th>Amount</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {earnings.map((earningsItem, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={idx}>
                    <td>
                      <EarningSummary earning={earningsItem} />
                    </td>
                    <td>
                      <FormattedCurrency value={earningsItem.totalAmount} />
                    </td>
                    <td className="pr-3">
                      <Link
                        variant="secondary"
                        as={Button}
                        to={`/earnings/${username}/${formatForRoute(
                          earningsItem.startDate,
                        )}/${formatForRoute(earningsItem.endDate)}`}
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Layout>
        </Layout>
      </MainContent>
    </AppPage>
  );
}

EarningsPage.getData = getData;

export default EarningsPage;
