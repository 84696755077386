import * as Sentry from '@sentry/browser';

const dsn = window.bflyConfig.SENTRY_DSN;

if (dsn) {
  Sentry.init({
    dsn,
    release: process.env.VERSION,
  });
}

export default {};
