/* eslint-disable react/no-array-index-key */
import { stylesheet } from 'astroturf';
import cx from 'classnames';
import noop from 'lodash/noop';
import React, { useCallback, useContext, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import NestedForm from 'react-formal/NestedForm';
import Layout from '@4c/layout';
import CloseSheet from '@bfly/icons/CloseSheet';
import Button from '@bfly/ui/Button';
import Card from '@bfly/ui/Card';
import Form from '@bfly/ui/Form';
import Header from '@bfly/ui/Header';

const styles = stylesheet`
  @import '~@bfly/ui/styles/theme';

  .item {
    &:first-of-type:last-of-type {
      border-bottom: 1px solid $grey-80;
    }

    &,
    &:hover {
      border-color: $grey-80;
    }

    overflow: visible !important;
  }

  .labelContainer {
    padding: 1rem 1.5rem;
  }

  .title {
    font-weight: $font-weight-bold;
  }

  .remove:disabled {
    opacity: 0.5;
  }
`;

type ItemProps = {
  name: string;
  className?: string;
  as?: React.ElementType;
  children: React.ReactNode;
  title: React.ReactNode;
  onRemove: (event) => void;
  disabled?: boolean;
};

type ToggleContextValue = (string) => void;

const ToggleContext = React.createContext<ToggleContextValue>(noop);

function AccordionFieldArrayItem({
  as = Card,
  children,
  className,
  name,
  title,
  onRemove,
  disabled = false,
  ...props
}: ItemProps) {
  const setActive = useContext(ToggleContext);

  if (disabled) return <Card.Header className="py-3">{title}</Card.Header>;

  return (
    <NestedForm
      {...props}
      as={as}
      name={name}
      className={cx(styles.item, className)}
    >
      <Accordion.Toggle
        as="div"
        eventKey={name}
        onClick={() => setActive(name)}
      >
        <Card.Header className="py-3" css="cursor: pointer;">
          {title}
          <Header.Actions>
            <Header.Action
              type="button"
              onClick={onRemove}
              className={styles.remove}
            >
              <CloseSheet height="1.5rem" />
            </Header.Action>
          </Header.Actions>
        </Card.Header>
      </Accordion.Toggle>

      <Accordion.Collapse eventKey={name}>
        <Card.Body>{children}</Card.Body>
      </Accordion.Collapse>
    </NestedForm>
  );
}

type Helpers<T> = {
  remove: (item: T) => (event: MouseEvent) => void;
};

type Props<T> = {
  name: string;
  label: string;
  labelSrOnly?: boolean;
  className?: string;
  addMessage?: string;
  defaultValue?: Partial<T>;
  disabled?: boolean;
  events?: string;
  children: ({
    value,
    helpers,
  }: {
    value: T[];
    helpers: Helpers<T>;
  }) => React.ReactNode;
};

function AccordionFieldArray<T>({
  className,
  name,
  children,
  defaultValue = {},
  label,
  labelSrOnly,
  addMessage = 'Add',
  disabled = false,
  events = 'blur',
}: Props<T>) {
  const [active, setActive] = useState('');

  const handleToggleActive = useCallback(
    (activeName) => {
      setActive(active === activeName ? '' : activeName);
    },
    [active],
  );

  return (
    <Form.FieldArray
      name={name}
      validates={events === 'all' ? undefined : events}
    >
      {(value, arrayHelpers, meta) => {
        const canModify = !disabled && (!value || !value.length || meta.valid);

        const handleAdd = () => {
          arrayHelpers.add(defaultValue);
          setActive(`${name}[${value ? value.length : 0}]`);
        };

        const handleRemove = (val) => (e) => {
          e.stopPropagation();
          setActive('');
          arrayHelpers.remove(val);
        };

        const helpers = {
          remove: handleRemove,
        };

        return (
          <>
            <Layout
              align="center"
              justify="space-between"
              className={styles.labelContainer}
            >
              <div className={styles.title}>
                <span className={labelSrOnly ? 'sr-only' : undefined}>
                  {label}
                </span>
              </div>
              <Button
                variant="text-secondary"
                disabled={!canModify}
                onClick={handleAdd}
              >
                {addMessage}
              </Button>
            </Layout>

            {value && (
              <ToggleContext.Provider value={handleToggleActive}>
                <Accordion activeKey={active} className={className}>
                  {children({ value, helpers })}
                </Accordion>
              </ToggleContext.Provider>
            )}
          </>
        );
      }}
    </Form.FieldArray>
  );
}

AccordionFieldArray.Item = AccordionFieldArrayItem;
export default AccordionFieldArray;
