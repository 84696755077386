/*
 *  This page serves as the Inference Inspector landing page and displays
 *  a searchable data-table containing most-recently produced inference results.
 */
import { stylesheet } from 'astroturf';
import { Match } from 'found';
import Link from 'found/Link';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';
import Layout from '@4c/layout';
import Button from '@bfly/ui/Button';
import DropdownList from '@bfly/ui/DropdownList';
import Header from '@bfly/ui/Header';
import LoadingIndicator from '@bfly/ui/LoadingIndicator';
import MainContent from '@bfly/ui/MainContent';
import useEventListener from '@restart/hooks/useEventListener';

import AppPage from 'src/components/AppPage';
import { useApi } from 'src/components/AuthProvider';
import SearchBox from 'src/components/SearchBox';
import Table from 'src/components/Table';
import { DlResult, DlcOrganization, User } from '../models';
import { compareVersions } from '../utils/compareByVersion';
import * as qs from '../utils/querystring';
import useCursorPaginationHandler from '../utils/useCursorPaginationHandler';

const styles = stylesheet`
  @import '~@bfly/ui/styles/theme';

  .selectRow {
    height: 14rem;
    border-radius: 0.8rem;
    background-color: $grey-80;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .projectDropdown {
    width: 45%;
  }

  .versionDropdown {
    width: 20%;
  }

  .organizationDropdown {
    width: 30%;
  }

  .searchBox {
    width: 45%;
  }

  .table {
    thead {
      font-weight: 800;
    }

    thead > tr > th:last-child,
    tbody > tr > td:last-child {
      padding-right: spacer(5);
      text-align: right;
    }
  }

  .footer {
    width: 100%;
    text-align: center;
    padding: 1rem;
  }
`;

const messages = defineMessages({
  organizationPlaceholder: {
    id: 'organization.placeholder',
    defaultMessage: 'Organization',
  },
  projectPlaceholder: {
    id: 'project.placeholder',
    defaultMessage: 'Project',
  },
  versionPlaceholder: {
    id: 'version.placeholder',
    defaultMessage: 'Version',
  },
  emptyOption: {
    id: 'option.empty',
    defaultMessage: 'All',
  },
});

const getProjectVersionMapping = (modelVersions) => {
  const projectVersionMapping = {};
  modelVersions.forEach((modelVersion) => {
    const {
      project,
      version,
      model_version_id: modelVersionId,
    } = modelVersion;

    if (!projectVersionMapping[project]) {
      projectVersionMapping[project] = { [version]: modelVersionId };
    } else {
      projectVersionMapping[project][version] = modelVersionId;
    }
  });

  return projectVersionMapping;
};

const getOrganizationMapping = (organizations) => {
  const organizationMapping = {};
  organizations.forEach((org) => {
    organizationMapping[org.organization_name] = org.organization_id;
  });
  return organizationMapping;
};

const getVersionOptions = (projectVersionMapping) => {
  const versionOptions = {};
  Object.keys(projectVersionMapping).forEach((project) => {
    versionOptions[project] = Object.keys(projectVersionMapping[project]).sort(
      (a, b) => -compareVersions(a, b),
    );
  });
  return versionOptions;
};

const hasFieldStartsWith = (
  object: object,
  fieldNames: Array<string>,
  stringToMatch: string,
): boolean =>
  fieldNames.some((column) =>
    object[column]?.toLowerCase().startsWith(stringToMatch.toLowerCase()),
  );

async function getData({ context }) {
  const { api } = context;
  const [viewer, modelVersions, organizations] = await Promise.all([
    AppPage.getData({ context }),
    api.getDlcModelVersions(),
    api.getDlcOrganizations(),
  ]);

  const projectVersionMapping = getProjectVersionMapping(modelVersions);
  const organizationMapping = getOrganizationMapping(organizations);
  const projectOptions = Object.keys(projectVersionMapping).sort();
  const versionOptions = getVersionOptions(projectVersionMapping);

  return {
    viewer,
    organizationMapping,
    projectVersionMapping,
    projectOptions,
    versionOptions,
    organizations,
  };
}

interface Props {
  data: {
    viewer: User;
    organizationMapping: {
      organizationName: string;
    };
    projectVersionMapping: {
      project: {
        version: string;
      };
    };
    projectOptions: string[];
    versionOptions: {
      project: string[];
    };
    organizations: DlcOrganization[];
  };
  match: Match;
}

function InferenceResultListPage({ data, match: { location } }: Props) {
  const {
    viewer,
    organizationMapping,
    projectOptions,
    versionOptions,
    projectVersionMapping,
    organizations,
  } = data;
  const [project, setProject] = useState<string | null>(null);
  const [version, setVersion] = useState<string | null>(null);
  const [organization, setOrganization] = useState<string | null>(null);
  const [modelVersion, setModelVersion] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchBoxValue, setSearchBoxValue] = useState('');
  const [filteredDlResultList, setFilteredDlResultList] = useState<DlResult[]>(
    [],
  );
  const api = useApi();
  const base = location.pathname;

  useMemo(() => {
    const {
      project: queryProject,
      version: queryVersion,
      organization: queryOrganization,
      search_term: querySearch,
    } = location.query;
    if (queryProject) {
      setProject(queryProject);
      if (queryVersion) {
        const modelVersionId =
          projectVersionMapping[queryProject][queryVersion];
        if (modelVersionId) {
          setVersion(queryVersion);
          setModelVersion(modelVersionId);
        }
      }
    }

    if (queryOrganization) {
      setOrganization(queryOrganization);
    }
    if (querySearch) {
      setSearchTerm(querySearch);
      setSearchBoxValue(querySearch);
    }
  }, [location.query, projectVersionMapping]);

  const nextResults = useCursorPaginationHandler(
    (...args) => api.getDlResults(...args),
    {
      project,
      dlModelVersionId: modelVersion,
      organizationId: organization && organizationMapping[organization],
      search: searchTerm,
    },
    [project, modelVersion, organization, searchTerm],
  );

  const debouncedHandleScroll = useMemo(
    () =>
      debounce(() => {
        if (!nextResults.hasMore || nextResults.isLoading) {
          return;
        }
        if (
          window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight
        ) {
          setFilteredDlResultList(nextResults.accumulatedData);
          nextResults.getNext();
        }
      }, 200),
    [nextResults],
  );

  useEffect(() => {
    const query = qs.stringify({
      project,
      version,
      organization,
      searchTerm: searchTerm.length ? searchTerm : null,
    });
    const url = query.length ? `${base}?${query}` : base;
    window.history.replaceState({}, '', url);

    document.title = `DLC Inference Results - Butterfly`;
  }, [project, version, organization, searchTerm, base]);

  useEventListener(window, 'scroll', debouncedHandleScroll);

  const handleProjectChange = (value) => {
    setFilteredDlResultList(
      nextResults.accumulatedData.filter(
        (dlResult) => dlResult.project === value,
      ),
    );
    setVersion(null);
    setModelVersion(null);
    setProject(value);
  };

  const handleResetSelections = () => {
    setFilteredDlResultList([]);
    setVersion(null);
    setProject(null);
    setSearchTerm('');
    setSearchBoxValue('');
    setOrganization(null);
    setModelVersion(null);
  };

  const handleVersionChange = (value) => {
    setVersion(value);
    const modelVersionId = project && projectVersionMapping[project][value];
    setFilteredDlResultList(
      nextResults.accumulatedData.filter(
        (dlResult) => dlResult.dl_model_version_id === modelVersionId,
      ),
    );
    setModelVersion(modelVersionId);
  };

  const handleOrganizationChange = (value) => {
    setFilteredDlResultList(
      nextResults.accumulatedData.filter(
        (dlResult) => dlResult.organization_id === organizationMapping[value],
      ),
    );
    setOrganization(value);
  };

  const handleSearchInputChange = useMemo(
    () => (searchInput) => {
      setFilteredDlResultList(
        nextResults.accumulatedData.filter((dlResult) =>
          hasFieldStartsWith(
            dlResult,
            [
              'organization_id',
              'image_id',
              'tool_name',
              'tool_mode',
              'archive_id',
              'study_id',
              'project',
            ],
            searchInput,
          ),
        ),
      );
    },
    [nextResults.accumulatedData],
  );

  const debouncedFrontendSearch = useMemo(
    () => debounce(handleSearchInputChange, 200),
    [handleSearchInputChange],
  );

  const handleSearchBoxChange = (value) => {
    setSearchBoxValue(value);
    debouncedFrontendSearch(value);
  };

  const getOrganizationName = (organizationId) =>
    organizations.find((org) => org.organization_id === organizationId)
      ?.organization_name;

  return (
    <AppPage viewer={viewer}>
      <Header>
        <Header.Title>DLC Inference Results</Header.Title>
      </Header>
      <MainContent size="large">
        <Layout
          direction="column"
          justify="center"
          className={styles.selectRow}
          pad
        >
          <Layout pad justify="space-between">
            <DropdownList
              className={styles.projectDropdown}
              dataKey="name"
              textField="name"
              placeholder={messages.projectPlaceholder}
              data={projectOptions}
              emptyMessage={messages.emptyOption}
              allowEmpty
              value={project}
              onChange={(v) => handleProjectChange(v)}
            />
            <DropdownList
              className={styles.versionDropdown}
              dataKey="name"
              textField="name"
              placeholder={messages.versionPlaceholder}
              data={project ? versionOptions[project] : []}
              emptyMessage={messages.emptyOption}
              allowEmpty
              value={version}
              onChange={handleVersionChange}
              disabled={!project}
            />
            <DropdownList
              className={styles.organizationDropdown}
              dataKey="name"
              textField="name"
              placeholder={messages.organizationPlaceholder}
              data={Object.keys(organizationMapping).sort()}
              emptyMessage={messages.emptyOption}
              allowEmpty
              value={organization}
              onChange={handleOrganizationChange}
            />
          </Layout>
          <Layout justify="space-between">
            <div className={styles.searchBox}>
              <SearchBox
                onSearch={setSearchTerm}
                onChange={handleSearchBoxChange}
                value={searchBoxValue}
              />
            </div>
            <Button
              size="large"
              variant="danger"
              onClick={handleResetSelections}
            >
              Reset
            </Button>
          </Layout>
        </Layout>
        <Table className={`mt-4 ${styles.table}`} variant="styled-header">
          <thead>
            <tr>
              <th>Project</th>
              <th>Version</th>
              <th>Tool Name</th>
              <th>Tool Mode</th>
              <th>Organization</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {(nextResults.isLoading
              ? filteredDlResultList
              : nextResults.accumulatedData
            ).map((dlResult) => (
              <tr key={dlResult.created_at}>
                <td>
                  <Link
                    to={`/-/admin/dlc/inference-inspector/${dlResult.dl_result_id}`}
                  >
                    <div>{dlResult.project}</div>
                  </Link>
                </td>
                <td>{dlResult.version}</td>
                <td>{dlResult.tool_name}</td>
                <td>{dlResult.tool_mode}</td>
                <td>{getOrganizationName(dlResult.organization_id)}</td>
                <td>
                  <div className="pl-4">
                    {new Date(dlResult.created_at).toLocaleString('en-US')}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {nextResults.isLoading && <LoadingIndicator />}
        {!nextResults.isLoading && !nextResults.hasMore && (
          <div className={styles.footer}>All results loaded</div>
        )}
      </MainContent>
    </AppPage>
  );
}

InferenceResultListPage.getData = getData;

export default InferenceResultListPage;
