import React, { useContext } from 'react';
import { object, string } from 'yup';
import Layout from '@4c/layout';
import Button from '@bfly/ui/Button';
import Form from '@bfly/ui/Form';
import Modal from '@bfly/ui/Modal';
import Text from '@bfly/ui/Text';
import ToastContext, { ToastManager } from '@bfly/ui/ToastContext';

import { useApi } from './AuthProvider';
import { Worklist } from '../schema/Worklist';
import executeWithErrorToast from 'src/utils/executeWithErrorToast';

const schema = object({
  worklistName: string().required('Curation worklist name is required'),
});

interface Props {
  show: boolean;
  setHideCurateModal: (boolean) => void;
  worklist: Worklist;
}

function CurateWorklistModal({ show, setHideCurateModal, worklist }: Props) {
  const toast = useContext<ToastManager | null>(ToastContext);
  const api = useApi();

  const handleSubmit = async (value): Promise<any> => {
    const result = await executeWithErrorToast(toast, () =>
      api.createAcceptRejectAssignments(worklist.id, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        worklist_name: value.worklistName,
      }),
    );
    setHideCurateModal(true);
    return result;
  };

  const { childrenWorklist, numCompletedAssignments, numAssignments } =
    worklist;

  const disabled =
    childrenWorklist.length > 0 || numCompletedAssignments !== numAssignments;

  return (
    <Modal show={show} onHide={() => setHideCurateModal(true)}>
      <Form schema={schema as any} onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>Create Curation Worklist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Layout pad={5} direction="column">
            <Text variant="body">
              A worklist with curation tasks will be assigned to you with all
              of the completed assignments within &quot;{worklist.name}&quot;
              worklist.
            </Text>

            <Form.FieldGroup
              name="worklistName"
              label="Name"
              placeholder="Curation Worklist Name"
            />
          </Layout>
        </Modal.Body>

        <Modal.Footer>
          <Modal.ButtonGroup>
            <Form.Submit as={Button} disabled={disabled} size="large">
              Create
            </Form.Submit>

            <Button
              size="large"
              variant="text-secondary"
              onClick={() => setHideCurateModal(true)}
            >
              Cancel
            </Button>
          </Modal.ButtonGroup>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CurateWorklistModal;
