import React, { lazy, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import store from 'store/dist/store.modern';
import Layout from '@4c/layout';
import BlankSlate from '@bfly/ui/BlankSlate';
import Header from '@bfly/ui/Header';
import LoadingIndicator from '@bfly/ui/LoadingIndicator';
import MainContent from '@bfly/ui/MainContent';

import AppPage from 'src/components/AppPage';
import ProjectFilterDropdown from 'src/components/ProjectFilterDropdown';
import TasksDropdown from 'src/components/TasksDropdown';
import { Project, Task } from '../models';

const CineTaskLabelsChart = lazy(
  () =>
    import(
      /* webpackChunkName: "analytic-charts" */
      '../components/CineTaskLabelsChart'
    ),
);

const TaskAnnotatorTimingsChart = lazy(
  () =>
    import(
      /* webpackChunkName: "analytic-charts" */
      '../components/TaskAnnotatorTimingsChart'
    ),
);

const CineCountsPerTaskChart = lazy(
  () =>
    import(
      /* webpackChunkName: "analytic-charts" */
      '../components/CineCountsPerTaskChart'
    ),
);

const TaskAnnotatorVelocityChart = lazy(
  () =>
    import(
      /* webpackChunkName: "analytic-charts" */
      '../components/TaskAnnotatorVelocityChart'
    ),
);

const TASK_STORE_KEY = '@bfly-label-collector/task-analytics';

async function getData({ context }) {
  const { api } = context;

  const initialTask = store.get(TASK_STORE_KEY, null);

  const [viewer, tasks, projects] = await Promise.all([
    AppPage.getData({ context }),
    api.getTasks(),
    api.getProjects(),
  ]);

  return {
    initialTask,
    viewer,
    tasks,
    projects,
  };
}

function TaskAnalyticsPage({ data }) {
  const { viewer, initialTask, projects } = data;

  const [tasks, setTasks] = useState(data.tasks);
  const [task, setTask] = useState<Task | null>(initialTask);
  const [project, setProject] = useState<Project>();

  const isCineTask = task && task.latest_version.definition.type === 'cine';

  const handleChangeProject = (selectedProject) => {
    const filteredTasksList = selectedProject
      ? data.tasks.filter((t) => selectedProject.tasks.includes(t.name))
      : data.tasks;

    setTasks(filteredTasksList);
    setTask(filteredTasksList.length > 0 ? filteredTasksList[0] : null);
    setProject(selectedProject);
  };

  const handleChangeTask = (t) => {
    setTask(t);
    store.set(TASK_STORE_KEY, t);
  };

  useEffect(() => {
    document.title = `Task Analytics - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Header>
        <Header.Title>Task Analytics</Header.Title>
      </Header>
      <MainContent size="large">
        <Layout justify="flex-start" pad>
          {projects.length > 0 && (
            <ProjectFilterDropdown
              projects={projects}
              value={project && project.name}
              onChange={handleChangeProject}
            />
          )}

          <TasksDropdown
            tasks={tasks}
            value={task && task.name}
            onChange={handleChangeTask}
          />
        </Layout>

        <React.Suspense fallback={<LoadingIndicator />}>
          {!task ? (
            <BlankSlate>
              <BlankSlate.Title>
                <FormattedMessage
                  defaultMessage="Select a Task"
                  id="taskAnalytics.title"
                />
              </BlankSlate.Title>
              <BlankSlate.Body>
                <FormattedMessage
                  defaultMessage="Please select a task to see analytics data."
                  id="taskAnalytics.body"
                />
              </BlankSlate.Body>
            </BlankSlate>
          ) : (
            <>
              {isCineTask && <CineTaskLabelsChart task={task} />}
              <TaskAnnotatorVelocityChart task={task} />
              <TaskAnnotatorTimingsChart task={task} />
              <CineCountsPerTaskChart task={task} />
            </>
          )}
        </React.Suspense>
      </MainContent>
    </AppPage>
  );
}

TaskAnalyticsPage.propTypes = {};
TaskAnalyticsPage.getData = getData;

export default TaskAnalyticsPage;
