import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import useGlobalListener from '@restart/hooks/useGlobalListener';

import parseShortcut from '../utils/keyboardShortcutParser';

const RegisterContext = React.createContext(() => {});
const HandlerContext = React.createContext(null);

const propTypes = {
  delimiter: PropTypes.string,
};

export const useShortcut = (charOrHash, handler) => {
  const register = useContext(RegisterContext);

  useEffect(
    () => register(charOrHash, handler),
    [charOrHash, handler, register],
  );
};

export function useTriggerShortcut(char) {
  const handlers = useContext(HandlerContext);
  const lowerChar = char.toLowerCase();

  return (event) => {
    handlers.forEach(([c, handler]) => {
      if (c.toLowerCase() === lowerChar) {
        handler(event.nativeEvent);
      }
    });
  };
}

export const useShortcuts = useShortcut;

export const withShortcuts = (Component) => (props) =>
  (
    <RegisterContext.Consumer>
      {(register) => <Component registerShortcut={register} {...props} />}
    </RegisterContext.Consumer>
  );

function KeyboardShortcutManager(props) {
  const { delimiter = '+' } = props;

  const data = useRef(new Set());

  const register = useCallback((charOrHash, fn) => {
    const { current: handlers } = data;

    const entries =
      charOrHash && fn ? [[charOrHash, fn]] : Object.entries(charOrHash);

    entries.map((entry) => handlers.add(entry));

    return () => entries.forEach((entry) => handlers.delete(entry));
  }, []);

  useGlobalListener('keydown', (event) => {
    const { current: handlers } = data;

    if (event.target.tagName.match(/input|textarea|select/i)) {
      return;
    }
    // iterate first in case a handler triggers an update that sets a new handler
    const entries = [...handlers.values()];

    for (const [chars, handler] of entries) {
      const parser = parseShortcut(chars, delimiter);
      if (parser(event)) {
        handler(event);
        event.preventDefault();
      }
    }
  });

  return (
    <HandlerContext.Provider value={data.current}>
      <RegisterContext.Provider value={register} {...props} />
    </HandlerContext.Provider>
  );
}

KeyboardShortcutManager.propTypes = propTypes;

export default KeyboardShortcutManager;
