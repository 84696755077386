/**
 * Specifies how LabelCollector URLs are routed.
 */
import HttpError from 'found/HttpError';
import Redirect from 'found/Redirect';
import RedirectException from 'found/RedirectException';
import Route from 'found/Route';
import React from 'react';

import CompletedWorklistsPage from 'src/components/CompletedWorklistsPage';
import HomePage from 'src/components/HomePage';
import EarningsPage from 'src/pages/EarningsPage';
import EarningsPeriodPage from 'src/pages/EarningsPeriodPage';
import EditWorklistAssignmentPage from 'src/pages/EditWorklistAssignmentPage';
import LoginPage from 'src/pages/LoginPage';
import LogoutPage from 'src/pages/LogoutPage';
import WorklistAssignmentPage from 'src/pages/WorklistAssignmentPage';
import adminRoutes from './admin';
import internalRoutes from './internal';

function rootRender({ match }) {
  if (match.context.api.username) {
    throw new RedirectException(`/${match.context.api.username}`);
  }
  return null;
}

const NotFound = (
  <Route
    render={() => {
      throw new HttpError(404);
    }}
  >
    <Route path="*" />
  </Route>
);

export default (
  <Route>
    <Route path="/" render={rootRender} />

    <Route allowPublic path="login" Component={LoginPage} />
    <Route allowPublic path="logout" Component={LogoutPage} />

    {adminRoutes}

    {internalRoutes}

    <Route path="earnings">
      <Redirect to="/" />
      <Route path=":username">
        <Route Component={EarningsPage} getData={EarningsPage.getData} />
        <Route
          path=":startDate/:endDate?"
          Component={EarningsPeriodPage}
          getData={EarningsPeriodPage.getData}
        />
      </Route>
    </Route>

    <Route path=":username">
      <Route Component={HomePage} getData={HomePage.getData} />
      <Route
        path="completed"
        Component={CompletedWorklistsPage}
        getData={CompletedWorklistsPage.getData}
      />

      <Route path="worklists/:worklistId/:assignmentId?">
        <Route
          Component={WorklistAssignmentPage}
          getData={WorklistAssignmentPage.getData}
        />

        <Route
          path="edit"
          Component={EditWorklistAssignmentPage}
          getData={EditWorklistAssignmentPage.getData}
        />
      </Route>
    </Route>

    {NotFound}
  </Route>
);
