import styled from 'astroturf/react';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import Layout from '@4c/layout';
import IcoCloseSheet from '@bfly/icons/CloseSheet';
import Button from '@bfly/ui/Button';
import DialogContext from '@bfly/ui/DialogContext';
import Form from '@bfly/ui/Form';
import MainContent from '@bfly/ui/MainContent';
import ToastContext from '@bfly/ui/ToastContext';

import { useApi } from 'src/components/AuthProvider';
import Lightbox from 'src/components/Lightbox';
import Textarea from 'src/components/Textarea';
import TaskReferenceImage from 'src/schema/TaskReferenceImage';
import RequestQueue from 'src/utils/RequestQueue';

const ReferenceImage = styled('img')`
  @import '~@bfly/ui/styles/theme';

  display: block;
  width: 14rem;
  height: 14rem;
  padding: 0.2rem;
  background-color: black;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid $grey-50;
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: $blue;
  }
`;

const ListItemBody = styled(Layout)`
  width: 100%;
  flex: 1;

  & > *:first-child,
  & textarea {
    width: 100%;
    height: 100%;
    flex: 1;
    resize: none;
  }
`;

function readAsBase64(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      const base64 = fr.result.split(',')[1];
      resolve(base64);
    };
    fr.onerror = (err) => {
      fr.abort();
      reject(err);
    };
    fr.readAsDataURL(file);
  });
}

const propTypes = {
  data: PropTypes.object.isRequired,
};

async function getData({ params, context }) {
  const { taskId } = params;
  const { api } = context;

  return {
    task: await api.getLatestTask(taskId),
  };
}

function TaskReferenceImageItem({ image, onChange, onDelete }) {
  const [open, setOpen] = useState(null);

  const url = useApi().getTaskReferenceImageUrl(image.id);

  return (
    <Form
      as="li"
      className="mb-4 d-flex"
      onChange={onChange}
      defaultValue={image}
      schema={TaskReferenceImage}
    >
      <ReferenceImage src={url} onClick={() => setOpen(true)} />
      <ListItemBody className="ml-3" align="flex-start">
        <Form.Field
          as={Textarea}
          name="description"
          className="flex-grow"
          placeholder="Add a description for this image…"
        />
        <Button
          theme="link"
          className="px-4 py-2"
          onClick={() => onDelete(image)}
          title="Delete Reference Image"
        >
          <IcoCloseSheet height="16px" />
        </Button>
      </ListItemBody>
      <Lightbox
        show={open}
        onHide={() => setOpen(false)}
        images={[{ ...image, src: url }]}
      />
    </Form>
  );
}

function useForceUpdate() {
  const [, setState] = useState(1);
  return () => {
    setState((s) => s + 1);
  };
}

function TaskReferenceImagePage({ data }) {
  const { task } = data;
  const forceUpdate = useForceUpdate();
  const dialog = useContext(DialogContext);
  const api = useApi();
  const toast = useContext(ToastContext);

  const requestQueue = useMemo(
    () =>
      new RequestQueue((id, referenceImage) =>
        api
          .updateTaskReferenceImage(id, referenceImage)
          .catch((e) => toast.error(e.message))
          .then(() => {
            forceUpdate();
          }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleUpload = async ({ target: { files } }) => {
    const _ = await Promise.all(
      Array.from(files).map(async (file) => {
        try {
          const imageData = await readAsBase64(file);
          return api.createTaskReferenceImage(task, imageData);
        } catch (e) {
          toast.error(e.message);
        }
        return null;
      }),
    );

    forceUpdate();
  };

  const handleDelete = async (image) => {
    const answer = await dialog.open(
      'Are you sure you want to delete this Reference Image?',
      {
        title: 'Delete Reference Image?',
        confirmLabel: 'Delete',
      },
    );

    if (answer) {
      await api.deleteTaskReferenceImage(image);
      forceUpdate();
    }
  };

  return (
    <MainContent size="medium">
      <ul className="list-unstyled mb-5">
        {task.latest_version.reference_images.map((image) => (
          <TaskReferenceImageItem
            key={image.id}
            image={image}
            task={task}
            onDelete={handleDelete}
            onChange={(value) => requestQueue.save(image.id, value)}
          />
        ))}
      </ul>
      <Layout justify="center">
        <Button as="label" theme="link" type={null}>
          <input
            type="file"
            multiple
            className="sr-only"
            accept=".png,.jpeg,jpg,.gif"
            onChange={handleUpload}
          />
          Add Reference Image
        </Button>
      </Layout>
    </MainContent>
  );
}

TaskReferenceImagePage.propTypes = propTypes;
TaskReferenceImagePage.getData = getData;

export default TaskReferenceImagePage;
