import { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { WorklistUploadRow } from '../models/Worklist';

interface UseCsvParams {
  csv: File | null;
  papaParseOptions: any;
  onError?: (error: Error) => void;
  fnValidateData?: (results: any) => boolean;
}
export function useCsvData({ csv, papaParseOptions }: UseCsvParams) {
  type State = {
    assignments: WorklistUploadRow[];
    errors: string[] | null;
  };

  const [state, setState] = useState<State>({
    assignments: [],
    errors: null,
  });

  useEffect(() => {
    if (!csv) return;
    //  validate if csv is a csv file
    if (typeof csv !== null) {
      if (csv.type !== 'application/vnd.ms-excel' && csv.type !== 'text/csv') {
        throw new Error('Invalid File Type');
      }
    }

    Papa.parse(csv, {
      ...papaParseOptions,
      complete(results) {
        const { data, errors } = results;
        setState({
          assignments: data,
          errors,
        });
      },
      error(error) {
        throw new Error(`Unexpected error: ${error}`);
      },
    });
  }, [csv, papaParseOptions]);

  return state;
}
