import { Router } from 'found';
import React, { useContext, useEffect, useMemo } from 'react';
import { object, string } from 'yup';
import Layout from '@4c/layout';
import Form from '@bfly/ui/Form';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';
import ToastContext, { ToastManager } from '@bfly/ui/ToastContext';

import AppPage from 'src/components/AppPage';
import { useApi } from 'src/components/AuthProvider';
import Page from 'src/components/Page';
import { Project, User } from '../models';
import executeWithErrorToast from '../utils/executeWithErrorToast';

type Props = {
  router: Router;
  data: {
    viewer: User;
    project?: Project;
  };
};

const schema = object({
  name: string()
    .required()
    .matches(/^[A-Z_]*$/, 'Only upper case and underscore are allowed.')
    .min(3),
});

async function getData({ params, context }) {
  const { name } = params;
  const { api } = context;

  return {
    viewer: await AppPage.getData({ context }),
    project: name ? await api.getProject(name) : null,
  };
}

function EditProjectPage({ data, router }: Props) {
  const { viewer, project } = data;
  const api = useApi();
  const toast = useContext<ToastManager | null>(ToastContext);

  const defaultValue = useMemo(
    () => (project ? schema.cast(project) : schema.default()),
    [project],
  );

  const submitForm = async (updated) => {
    await executeWithErrorToast(toast, () =>
      project
        ? api.editProject(project.name, updated)
        : api.createProject(updated),
    );

    if (project) {
      toast!.success('Update Successful');
      router.push(`/-/admin/projects/${updated.name}/edit`);
    } else {
      router.push(`/-/admin/projects/${updated.name}`);
    }
  };

  useEffect(() => {
    document.title = `Edit Project - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Page.Header backTo="/-/admin/projects">
        <Header.Title>{project ? `Edit` : 'New'} Project</Header.Title>
      </Page.Header>
      <MainContent size="medium">
        <Form
          onSubmit={submitForm}
          schema={schema as any}
          defaultValue={defaultValue}
        >
          <Form.FieldGroup name="name" label="Name" autoFocus horizontal />
          <Layout justify="flex-end">
            <Form.Submit>Save Project</Form.Submit>
          </Layout>
        </Form>
      </MainContent>
    </AppPage>
  );
}

EditProjectPage.getData = getData;

export default EditProjectPage;
