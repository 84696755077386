import styled from 'astroturf/react';
import React from 'react';
import Layout from '@4c/layout';
import LoadingIndicator from '@bfly/ui/LoadingIndicator';

const Sheet = styled(Layout).attrs({ align: 'center', justify: 'center' })`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 1;
`;

const Container = styled('div')`
  position: relative;
`;

type Props = {
  isLoading?: boolean;
  children?: React.ReactNode;
};

function LoadingSheet({ children, isLoading }: Props) {
  return (
    <Container>
      {isLoading && (
        <Sheet>
          <LoadingIndicator />
        </Sheet>
      )}
      {children}
    </Container>
  );
}

export default LoadingSheet;
