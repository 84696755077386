import { stylesheet } from 'astroturf';
import { Match } from 'found';
import AppPage from 'src/components/AppPage';
import orderBy from 'lodash/orderBy';
import { DlcImageProcessName, DlcProcess, User } from '../models';
import React, { useEffect, useMemo, useState } from 'react';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';
import Link from 'found/Link';
import Button from '@bfly/ui/Button';
import Table from 'src/components/Table';
import CheckIco from '@bfly/icons/CheckMarkSmall';
import Layout from '@4c/layout';
import DropdownList from '@bfly/ui/DropdownList';
import { defineMessages } from 'react-intl';
import * as qs from '../utils/querystring';

const styles = stylesheet`
  @import '~@bfly/ui/styles/theme';

  .selectRow {
    border-radius: 0.8rem;
    background-color: $grey-80;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .processDropdown {
    width: 45%;
  }

  .deployStatusDropdown {
    width: 20%;
  }

  .servableStatusDropdown {
    width: 20%;
  }

  .table {
    thead {
      font-weight: 800;
    }

    thead > tr > th:last-child,
    tbody > tr > td:last-child {
      padding-right: spacer(5);
      text-align: right;
    }
  }

  .footer {
    width: 100%;
    text-align: center;
    padding: 1rem;
  }
`;

const messages = defineMessages({
  deployStatusPlaceholder: {
    id: 'deployStatus.placeholder',
    defaultMessage: 'Deploy Status',
  },
  processPlaceholder: {
    id: 'process.placeholder',
    defaultMessage: 'Process',
  },
  servableStatusPlaceholder: {
    id: 'servableStatus.placeholder',
    defaultMessage: 'Servable Status',
  },
  emptyOption: {
    id: 'option.empty',
    defaultMessage: 'All',
  },
});

const DEPLOY_STATUSES = [
  { name: 'Deployed', value: true },
  { name: 'Not deployed', value: false },
];

const SERVABLE_STATUSES = [
  { name: 'Servable', value: true },
  { name: 'Not servable', value: false },
];

type DisplayProcess = DlcProcess & { preset: string | undefined };

async function getData({ context }) {
  const { api } = context;
  const [viewer, processNames, processes, presets] = await Promise.all([
    AppPage.getData({ context }),
    api.getDlcImagedProcessName(),
    api.getDlcProcesses(),
    api.getDlcPresets(),
  ]);
  const presetMap = new Map<string, string>();
  presets.forEach((p) => presetMap.set(p.preset_id, p.name));

  const displayProcesses: DisplayProcess[] = orderBy(
    processes,
    ['servable', 'deployed', 'created_at'],
    ['desc', 'desc', 'desc'],
  ).map((p) => ({ ...p, preset: presetMap.get(p.preset_id) }));
  return {
    viewer,
    presetMap,
    processNames,
    display: displayProcesses,
  };
}

interface Props {
  data: {
    viewer: User;
    presetMap: Map<string, string>;
    processNames: DlcImageProcessName[];
    display: DisplayProcess[];
  };
  match: Match;
}

function InferenceProcessListPage({ data, match: { location } }: Props) {
  const { viewer, presetMap, processNames, display } = data;
  const [process, setProcess] = useState<string | null>(null);
  const [deployStatus, setDeployStatus] = useState<boolean | null>(null);
  const [servableStatus, setServableStatus] = useState<boolean | null>(null);
  const base = location.pathname;

  const [filteredDisplayProcessList, setFilteredDisplayProcessList] =
    useState<DisplayProcess[]>(display);

  useMemo(() => {
    const { process: queryProcess, deployed, servable } = location.query;
    if (queryProcess) {
      setProcess(queryProcess);
    }
    if (deployed) {
      setDeployStatus(deployed === 'true');
    }
    if (servable) {
      setServableStatus(servable === 'true');
    }
  }, [location.query]);

  useEffect(() => {
    setFilteredDisplayProcessList(
      display.filter(
        (displayProcess) =>
          (!process || displayProcess.dlc_process_name === process) &&
          (deployStatus === null ||
            displayProcess.deployed === deployStatus) &&
          (servableStatus === null ||
            displayProcess.servable === servableStatus),
      ),
    );
    const query = qs.stringify({
      process,
      deployed: deployStatus,
      servable: servableStatus,
    });
    const url = query.length ? `${base}?${query}` : base;
    window.history.replaceState({}, '', url);
  }, [process, deployStatus, servableStatus, display, base]);

  const handleProcessNameChange = (name) => {
    setProcess(name ? name.value : null);
  };

  const handleDeployStatusChange = (status) => {
    setDeployStatus(status ? status.value : null);
  };

  const handleServableStatusChange = (status) => {
    setServableStatus(status ? status.value : null);
  };

  const handleResetSelections = () => {
    setFilteredDisplayProcessList(display);
    setProcess(null);
    setDeployStatus(null);
    setServableStatus(null);
  };

  useEffect(() => {
    document.title = `DLC Processes - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Header>
        <Header.Title>DLC Processes</Header.Title>
        <Header.Actions align="center">
          <Link as={Button} to="/-/admin/dlc/inference-processes/-/new">
            New
          </Link>
        </Header.Actions>
      </Header>
      <MainContent size="large">
        <Layout pad justify="space-between" className={styles.selectRow}>
          <DropdownList
            className={styles.processDropdown}
            placeholder={messages.processPlaceholder}
            emptyMessage={messages.emptyOption}
            dataKey="value"
            textField="value"
            allowEmpty
            data={processNames}
            value={process}
            onChange={handleProcessNameChange}
          />
          <DropdownList
            className={styles.deployStatusDropdown}
            placeholder={messages.deployStatusPlaceholder}
            emptyMessage={messages.emptyOption}
            dataKey="value"
            textField="name"
            allowEmpty
            data={DEPLOY_STATUSES}
            value={deployStatus}
            onChange={handleDeployStatusChange}
          />
          <DropdownList
            className={styles.servableStatusDropdown}
            placeholder={messages.servableStatusPlaceholder}
            emptyMessage={messages.emptyOption}
            dataKey="value"
            textField="name"
            allowEmpty
            data={SERVABLE_STATUSES}
            value={servableStatus}
            onChange={handleServableStatusChange}
          />
          <Button
            size="large"
            variant="danger"
            onClick={handleResetSelections}
          >
            Reset
          </Button>
        </Layout>
        <Table className={`mt-4 ${styles.table}`} variant="styled-header">
          <thead>
            <tr>
              <th>Process</th>
              <th>Type</th>
              <th>Preset</th>
              <th>Deployed</th>
              <th>Servable</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            {filteredDisplayProcessList.map(
              ({
                dlc_process_id: processId,
                dlc_process_name: processName,
                dlc_process_type: processType,
                preset_ids: presetIds,
                servable,
                deployed,
                created_at: createdAt,
              }) => (
                <tr key={processId}>
                  <td>
                    <Link to={`/-/admin/dlc/inference-processes/${processId}`}>
                      {processName}
                    </Link>
                  </td>
                  <td>{processType}</td>
                  <td>
                    {presetIds
                      .map((presetId) => presetMap.get(presetId))
                      .sort()
                      .map((preset, index) => (index ? ', ' : '') + preset)}
                  </td>
                  <td>{deployed && <CheckIco />}</td>
                  <td>{servable && <CheckIco />}</td>
                  <td>{new Date(createdAt).toLocaleString('en-US')}</td>
                </tr>
              ),
            )}
          </tbody>
        </Table>
      </MainContent>
    </AppPage>
  );
}

InferenceProcessListPage.getData = getData;

export default InferenceProcessListPage;
