import get from 'lodash/get';
import { useEffect, useState } from 'react';

import { useApi } from 'src/components/AuthProvider';
import * as Task from '../schema/AnnotationTask';
import * as DlResult from '../schema/DlResultOutputs';
import * as ReviewTask from '../schema/ReviewAnnotationTask';
import * as Worklist from '../schema/Worklist';
import getFileData from './getFileData';

const deserializeTask = (task) =>
  task &&
  (task.task_type === 'review'
    ? ReviewTask.deserialize(task)
    : Task.deserialize(task));

const getTask = ({ api, taskId, reviewTaskId, worklistId }) => {
  let worklistPromise, reviewTaskPromise;

  const taskPromise = api.getLatestTask(taskId);

  if (worklistId) {
    worklistPromise = api.getWorklist(worklistId);
  }

  if (reviewTaskId) {
    reviewTaskPromise = api.getLatestTask(reviewTaskId);
  }

  return Promise.all([worklistPromise, taskPromise, reviewTaskPromise]);
};

const getAssignment = async ({ assignmentId, worklistId, username, api }) => {
  let assignment, label, nextAssignment;
  let reviewAssignment, reviewingLabel;

  if (assignmentId) {
    label = await api.getLabel(assignmentId);
    assignment = label.assignment;
  } else {
    [assignment, nextAssignment] = await api.getNextAssignments({
      worklistId,
      username,
      limit: 2,
    });
  }

  if (assignment && assignment.review_assignment_id) {
    reviewingLabel = await api.getLabel(assignment.review_assignment_id);
    reviewAssignment = reviewingLabel.assignment;
  }

  return {
    assignment,
    label,
    nextAssignment,
    reviewAssignment,
    reviewingLabel,
  };
};

export async function fetch({ worklistId, username, assignmentId, api }) {
  const {
    assignment,
    nextAssignment,
    label,
    reviewAssignment,
    reviewingLabel,
    previousAssignment,
  } = await getAssignment({
    worklistId,
    username,
    assignmentId,
    api,
  });

  let dlResultOutputs, compFile, compFrames;

  if (!reviewAssignment && !assignment) return null;

  const {
    image_id: imageId,
    dl_result_id: dlResultId,
    start_frame: startFrame,
    end_frame: endFrame,
    comparison_image_id: comparisonImageId,
    comparison_image_position: comparisonImagePosition,
  } = reviewAssignment || assignment;

  if (dlResultId) {
    const dlResult = await api.getDlResultByDlResultId(dlResultId);
    dlResultOutputs = DlResult.deserialize(dlResult);
  }

  let { file, frames } = await getFileData({
    api,
    imageId,
    startFrame,
    endFrame,
  });

  if (comparisonImageId) {
    ({ file: compFile, frames: compFrames } = await getFileData({
      api,
      imageId: comparisonImageId,
      startFrame,
      endFrame,
    }));

    // Have to swap the two displayed images if comparison
    // position is "left".  This trickles down to all display
    // elements
    if (comparisonImagePosition === 'left') {
      ({
        file: compFile,
        compFile: file,
        frames: compFrames,
        compFrames: frames,
      } = {
        file,
        compFile,
        frames,
        compFrames,
      });
    }
  }

  const [worklist, task, reviewingTask] = await getTask({
    api,
    worklistId,
    taskId: assignment.task,
    reviewTaskId: reviewAssignment && reviewAssignment.task,
  });

  return {
    label,
    assignment,
    nextAssignment,
    reviewingLabel,
    previousAssignment,
    worklist: Worklist.deserialize(worklist),
    reviewingTask: deserializeTask(reviewingTask),
    task: deserializeTask(task),
    enabledFrames: get(assignment, 'specifics.enabled_frames', []).sort(
      (a, b) => a - b,
    ),
    dlResultOutputs,
    file,
    frames,
    compFile,
    compFrames,
    comparisonImagePosition,
  };
}

export async function* fetchData(frames) {
  const images = new Array(frames.length);

  let idx = 0;
  const pushImage = (img) => {
    images[idx++] = img;
  };

  for await (const imageChunk of frames) {
    imageChunk.forEach(pushImage);
    yield images.slice();
  }
}

/**
 * Prefetches the image data for an assignment by forcing the frames iterator
 * to run through and fill the Cine cache.
 *
 * @param {Assignment} assignment The server assignment data
 * @param {Api} api
 */
export async function prefetchData(assignment, api) {
  if (!assignment) return;
  if (assignment.review_assignment_id) {
    const [reviewAssignment] = await api.getAssignment(
      assignment.review_assignment_id,
    );
    // eslint-disable-next-line no-param-reassign
    assignment = reviewAssignment;
  }

  const {
    image_id: imageId,
    comparison_image_id: compImageId,
    start_frame: startFrame,
    end_frame: endFrame,
  } = assignment;

  const promises = [
    getFileData({
      api,
      imageId,
      startFrame,
      endFrame,
    }),
  ];

  if (compImageId) {
    promises.push(
      getFileData({
        api,
        imageId: compImageId,
        startFrame,
        endFrame,
      }),
    );
  }

  await Promise.all(promises);
}

export function useFrameData({ frames, compFrames, nextAssignment }) {
  const api = useApi();
  const [images, setImages] = useState(
    frames ? new Array(frames.length) : null,
  );

  const [compImages, setCompImages] = useState(
    compFrames ? new Array(compFrames.length) : null,
  );

  useEffect(() => {
    let timer;
    if (!frames) return undefined;

    const imageChunks = fetchData(frames, 'primary');
    let compImageChunks;
    if (compFrames) {
      compImageChunks = fetchData(compFrames, 'comparison');
    }

    async function readImages() {
      await Promise.all([
        // Load primary image_id frames
        (async () => {
          for await (const nextImages of imageChunks) {
            setImages(nextImages);
          }
        })(),

        // Load comparison_image_id frames
        (async () => {
          if (compImageChunks) {
            for await (const nextCompImages of compImageChunks) {
              setCompImages(nextCompImages);
            }
          }
        })(),
      ]);
    }

    readImages().then(() => {
      if (nextAssignment) {
        timer = setTimeout(() => {
          prefetchData(nextAssignment, api);
        }, 2000);
      }
    });

    return () => {
      if (imageChunks && imageChunks.return) imageChunks.return();
      if (compImageChunks && compImageChunks.return) compImageChunks.return();
      clearTimeout(timer);
    };
  }, [api, frames, compFrames, nextAssignment]);

  return { images, compImages };
}
