import React from 'react';
import DropdownList from '@bfly/ui/DropdownList';
import Form from '@bfly/ui/Form';

import { Trace } from '../schema/AnnotationTask';
import AccordionFieldArray from './AccordionFieldArray';

const defaultTrace = Trace.default();

interface Props {
  name?: string;
}

function TracesForm({ name = 'definition.traces' }: Props) {
  return (
    <Form.FieldSet legend="Traces">
      <AccordionFieldArray<Trace>
        name={name}
        label="Traces"
        labelSrOnly
        defaultValue={defaultTrace}
      >
        {({ value, helpers }) =>
          value.map((item, idx) => {
            const fieldName = `${name}[${idx}]`;

            return (
              <AccordionFieldArray.Item
                key={name}
                name={fieldName}
                onRemove={helpers.remove(item)}
                title={
                  item && item.displayText
                    ? `${idx + 1}. ${item.displayText}`
                    : 'New Trace'
                }
              >
                <Form.FieldGroup
                  name="type"
                  label="Type"
                  as={DropdownList}
                  data={['point', 'line', 'ellipse', 'polygon', 'rectangle']}
                  className="mb-4"
                  horizontal
                />

                <Form.FieldGroup
                  name="displayText"
                  label="Display Text"
                  className="mb-4"
                  horizontal
                />

                <Form.FieldGroup
                  name="traceId"
                  label="Trace ID"
                  className="mb-4"
                  horizontal
                />

                <Form.FieldGroup
                  name="interpolate"
                  label="Interpolate"
                  className="mb-4"
                  horizontal
                />

                <Form.FieldGroup
                  name="shortcut"
                  label="Keyboard Shortcut"
                  className="mb-4"
                  horizontal
                />

                <Form.FieldGroup
                  name="color"
                  label="Color"
                  className="mb-4"
                  horizontal
                />

                <Form.FieldGroup
                  name="numPoints"
                  label="Num. points"
                  type="number"
                  className="mb-4"
                  horizontal
                />

                <Form.FieldGroup
                  name="maxPerFrame"
                  label="Max per frame"
                  type="number"
                  className="mb-4"
                  horizontal
                />

                <Form.FieldGroup
                  name="required"
                  label="Required"
                  className="mb-4"
                  horizontal
                />

                <Form.FieldGroup
                  name="numFrames"
                  label="Num. frames"
                  type="number"
                  horizontal
                />
              </AccordionFieldArray.Item>
            );
          })
        }
      </AccordionFieldArray>
    </Form.FieldSet>
  );
}

export default TracesForm;
